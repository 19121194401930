import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, DatePicker,
    Accordion
} 
from '../../noser-hris-component';
import TrainingClient from '../Training/TrainingModal/TrainingClient';
import TrainingPosition from '../Training/TrainingModal/TrainingPosition';
import {CSVLink, CSVDownload} from 'react-csv';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

class Training extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            navigateChild   :   false,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            openClientModal :   false,
            openPositionModal:  false,
            clientId        :   "",
            trainingId      :   "",
            trainingDDL     :   [],
            trainingGrid    :   [],
            clientDDL       :   [],
            employeeDDL     :   [],
            statusDDL       :   [],
            statusId      :   "",
            requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}],
            gridDataExcel   :   [],
            resignationDate: "",
            clearanceDate: "",
            submissionDate: "",
            workingDate: "",
            toLastWorkingDate : "",
            fromLastWorkingDate : "",
            toDateReceived : "",
            fromDateReceived :"", 
            toClearanceDate : "",
            fromClearanceDate : "",
            toDateCreated : "",
            fromDateCreated : "",
            
            reasonDDL: [],
            batchNumber : "",
            reason : "",
            profileId : "",
            employeeClearance : []
            
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        console.log("hello")
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetClient()
    }
    GetTrainingFilters = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainingFilters",  param)
        .then(res => {
            const data = res.data;
            this.setState({trainingDDL:data.trainings,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetClient = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                        this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,})
            }
            else{
                this.setState({clientDDL : data.clients,})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        // this.GetEmployees();
        this.getStatusClearance();
    };
    GetEmployees = () => {
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   "0",
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList",  getParams)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .then(res => {
            const data = res.data
            console.log("GetEmployeeList")
            console.log(data)
            this.setState({
                employeeDDL        :   data.employees,
                //isloading           :   false,
            }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
        this.getStatusClearance();
    
    };
    getStatusClearance = async() =>{
        //this.setState({ isloading : true })
        const statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0040"
        };
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
        .then(res => {
            const data = res.data;
            this.setState({
                statusDDL : res.data.dataReferences
            })
        })
        this.GetDisengagementMode();
    }
    GetDisengagementMode = async() => {
        //this.setState({isloading:true})
        const param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            const data = res.data.disengagementMode;
            this.setState({
                reasonDDL: data,
            });

            if(data.status==="0"){
                this.setState(  {
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetDataGrid();
    };
    GetDataGrid = async() => {
       
        //this.setState({isloading:true})
        const param = {
            "IpAddress" : "0.0.0.0",
            "ClientId" : "",
            "UserId" : "35954",
            "ProfileId" : ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            const data = res.data.clearance;
            console.log(" clearance data")
            console.log(data.filter(x=>x.isClearanceStatus !== "9" || x.isClearanceStatus !=="10"))
            let modifyData =[]
            let userinfo = this.state.userinfo.userId
            if(data.filter(x=>x.isClearanceStatus === "0" || x.isClearanceStatus =="1"|| x.isClearanceStatus =="2"|| x.isClearanceStatus =="3"|| x.isClearanceStatus =="4"|| x.isClearanceStatus =="5"|| x.isClearanceStatus =="6"|| x.isClearanceStatus =="7"|| x.isClearanceStatus =="8").length > 0) {
                data.filter(x=>x.isClearanceStatus === "0" || x.isClearanceStatus =="1"|| x.isClearanceStatus =="2"|| x.isClearanceStatus =="3"|| x.isClearanceStatus =="4"|| x.isClearanceStatus =="5"|| x.isClearanceStatus =="6"|| x.isClearanceStatus =="7"|| x.isClearanceStatus =="8").map(function(itm,idx){
                    modifyData.push({
                        id : itm.id,
                        batchNumber : itm.batchNumber,
                        clientName : itm.clientName,
                        employeeNo : itm.employeeNo,
                        employeeName : itm.employeeName,
                        reasonForLeaving : itm.reasonForLeaving,
                        dateStart : itm.dateStart,
                        lastWorkingDateMember : itm.lastWorkingDateMember,
                        cashCardNumberMember : itm.cashCardNumberMember,
                        cbu : itm.cbu,
                        dividend : itm.dividend,
                        lastSalary : itm.lastSalary,
                        adjustment : itm.adjustment,
                        otherPayroll : itm.otherPayroll,
                        thirteenthMonth : itm.thirteenthMonth,
                        sil : itm.sil,
                        separationPay :itm.separationPay,
                        incentives : itm.incentives,
                        grossPay : itm.grossPay,
                        inclusions : itm.inclusions,
                        totalInclusion : itm.totalInclusion,
                        totalBalance : itm.totalBalance,
                        netPay : itm.netPay,
                        // noAtm : itm.noAtm,

                        batchPayroll : itm.batchPayroll,
                        typeOfCashCardMember : itm.typeOfCashCardMember,
                        areRemarks : itm.areRemarks,
                        clearanceDateMember : itm.clearanceDateMember,
                        clearanceDeductions : itm.clearanceDeductions,
                        clearanceDetails : itm.clearanceDetails,
                        clearanceStatus : itm.clearanceStatus,
                        clearedSubmit : itm.clearedSubmit,
                        clientId : itm.clientId,
                        closingRemarks : itm.closingRemarks,
                        createdBy : itm.createdBy,
                        createdByName : itm.createdByName,
                        createdDate : itm.createdDate,
                        cycleDays : itm.cycleDays,
                        dateCreated : itm.dateCreated,
                        dateForwardedToAmt : itm.dateForwardedToAmt,
                        dateOfBirth : itm.dateOfBirth,
                        dateReceivedByAdmin : itm.dateReceivedByAdmin,
                        dateReceivedFromAmt : itm.dateReceivedFromAmt,
                        dateReturnedByARE : itm.dateReturnedByARE,
                        dateReturnedByClient : itm.dateReturnedByClient,
                        dateReturnedByFinance : itm.dateReturnedByFinance,
                        dateReturnedByLRD : itm.dateReturnedByLRD,
                        dateReturnedByPayroll : itm.dateReturnedByPayroll,
                        dateReturnedByRMT : itm.dateReturnedByRMT,
                        dateReturnedByServices : itm.dateReturnedByServices,
                        dateSettle : itm.dateSettle,
                        dateSubmitted : itm.dateSubmitted,
                        dateSubmittedByLead : itm.dateSubmittedByLead,
                        dateSubmittedByStaff : itm.dateSubmittedByStaff,
                        dateUpdatedByARE : itm.dateUpdatedByARE,
                        dateUpdatedByClient : itm.dateUpdatedByClient,
                        dateUpdatedByFinance : itm.dateUpdatedByFinance,
                        dateUpdatedByLRD : itm.dateUpdatedByLRD,
                        dateUpdatedByRMT : itm.dateUpdatedByRMT,
                        dateUpdatedByServices : itm.dateUpdatedByServices,
                        employeeId : itm.employeeId,
                        finalized : itm.finalized,
                        financeRemarks : itm.financeRemarks,
                        firstName : itm.firstName,
                        generation : itm.generation,
                        // grossPay : itm.grossPay,
                        hdmfNumber : itm.hdmfNumber,
                        id : itm.id,
                        isCOERequestFormMember : itm.isCOERequestFormMember,
                        isCash : itm.isCash,
                        isCashCardMember : itm.isCashCardMember,
                        isCheckMember : itm.isCheckMember,
                        isCheckedByRmt : itm.isCheckedByRmt,
                        isClearanceStatus : itm.isClearanceStatus,
                        isClearedClient : itm.isClearedClient,
                        isClearedRmt : itm.isClearedRmt,
                        isClearedSubmit : itm.isClearedSubmit,
                        isDeleted : itm.isDeleted,
                        isEligible : itm.isEligible,
                        isEligibleClient : itm.isEligibleClient,
                        isFinalized : itm.isFinalized,
                        isForwardToARERmt : itm.isForwardToARERmt,
                        isGenerated : itm.isGenerated,
                        isHDMFNumberMember : itm.isHDMFNumberMember,
                        isMlhuillierMember : itm.isMlhuillierMember,
                        isModified : itm.isModified,
                        isModifiedByARE : itm.isModifiedByARE,
                        isNonEligibleClient : itm.isNonEligibleClient,
                        isNotEligible : itm.isNotEligible,
                        isPHICNumberMember : itm.isPHICNumberMember,
                        isPalawanMember : itm.isPalawanMember,
                        isResignationLetterMember : itm.isResignationLetterMember,
                        isRetrunedServicesRmt : itm.isRetrunedServicesRmt,
                        isReturnedRmt : itm.isReturnedRmt,
                        isSSSNumberMember : itm.isSSSNumberMember,
                        isSettleWithDole : itm.isSettleWithDole,
                        isTINNumberMember : itm.isTINNumberMember,
                        isTurnoverChecklistMember : itm.isTurnoverChecklistMember,
                        isTypeOfCashCardMember : itm.isTypeOfCashCardMember,
                        isValidIdMember : itm.isValidIdMember,
                        lastName : itm.lastName,
                        locationId : itm.locationId,
                        locationName : itm.locationName,
                        middleName : itm.middleName,
                        modifiedBy : itm.modifiedBy,
                        modifiedByName : itm.modifiedByName,
                        modifiedDate : itm.modifiedDate,
                        // netPay : itm.netPay,
                        notesMember : itm.notesMember,
                        paramountId : itm.paramountId,
                        payPeriodId : itm.payPeriodId,
                        paycardTypeIdMember : itm.paycardTypeIdMember,
                        payrollPeriods : itm.payrollPeriods,
                        payrollRemarks : itm.payrollRemarks,
                        periodCovered : itm.periodCovered,
                        phicNumber : itm.phicNumber,
                        positionId : itm.positionId,
                        positionName : itm.positionName,
                        profileId : itm.profileId,
                        reasonForLeaving : itm.reasonForLeaving,
                        reasonForLeavingId : itm.reasonForLeavingId,
                        remarksClient : itm.remarksClient,
                        remarksLrd : itm.remarksLrd,
                        remarksMember : itm.remarksMember,
                        remarksRmt : itm.remarksRmt,
                        resignationDateMember : itm.resignationDateMember,
                        returnedByARE : itm.returnedByARE,
                        returnedByClient : itm.returnedByClient,
                        returnedByFinance : itm.returnedByFinance,
                        returnedByLRD : itm.returnedByLRD,
                        returnedByRMT : itm.returnedByRMT,
                        returnedByServices : itm.returnedByServices,
                        reviewStatus : itm.reviewStatus,
                        reviewStatusResults : itm.reviewStatusResults,
                        rowIndex : itm.rowIndex,
                        servicesAttachments : itm.servicesAttachments,
                        servicesRemarks : itm.servicesRemarks,
                        // sil : itm.sil,
                        sssNumber : itm.sssNumber,
                        submissionDateMember : itm.submissionDateMember,
                        submitted : itm.submitted,
                        submittedByLead : itm.submittedByLead,
                        submittedByStaff : itm.submittedByStaff,
                        suffixName : itm.suffixName,
                        timeStamp : itm.timeStamp,
                        tinNumber : itm.tinNumber,
                        // totalBalance : itm.totalBalance,
                        updatedByARE : itm.updatedByARE,
                        updatedByClient : itm.updatedByClient,
                        updatedByFinance : itm.updatedByFinance,
                        updatedByLRD : itm.updatedByLRD,
                        updatedByRMT : itm.updatedByRMT,
                        updatedByServices : itm.updatedByServices,
                        validateByRmt : itm.validateByRmt,
                        batchPayroll : itm.batchPayroll,
                        dateHired : itm.dateHired,
                        contractDateStart : itm.contractDateStart,
                        contractDateEnd : itm.contractDateEnd,
                        // dateClosed : itm.completionDate,
                        isParent : itm.isParent,
                        isChild : itm.isChild,
                        parentId : itm.parentId,
                        
                        dateSubmittedToPayroll : itm.dateSubmittedToPayroll,
                        isAre : itm.isAre,
                        isPayroll : itm.isPayroll,
                        financePayoutDate : itm.financePayoutDate,
                        isUpdatedByARE : itm.isUpdatedByARE,
                        lastModifiedBy : itm.lastModifiedBy,
                        completionDate : itm.completionDate,
                    })
                })
                let employeeList = data.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.employeeName === thing.employeeName
                )))
                this.setState({trainingGrid : modifyData.sort((a, b) => a.id < b.id ? 1 : -1),  isloading   : false, employeeClearance: employeeList.sort((a, b) => a.employeeName < b.employeeName ? -1 : 1),})
            }
            // if(data.length === 1){
            //     this.setState({
            //         isDisabledDelete : false
            //     })
            // }else {
            //     this.setState({
            //         isDisabledDelete : true
            //     })
            // }
            // modifyData.filter(x=>x.isClearanceStatus !== "9" || x.isClearanceStatus !=="10").forEach(itm => {
            //     if(itm.isClearanceSubmitted === "1"){
            //         this.setState({
            //             clearanceId : itm.id,
            //             isClearanceSubmitted : true,
            //             dateCleared : new Date(itm.dateCleared),
            //             dateSubmitted : new Date(itm.dateSubmitted),
            //             remarks : itm.remarks
            //         })
            //     }else {
            //         this.setState({
            //             clearanceId : itm.id,
            //             isClearanceSubmitted : false,
            //             dateCleared : new Date(itm.dateCleared),
            //             dateSubmitted : new Date(itm.dateSubmitted),
            //             remarks : itm.remarks
            //         })
            //     }
            // });
            // this.setState({
            //     dataInfo    : modifyData,
            //     isloading   : false,
            // });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };


    handleTrainingChanged = (e) => {
        if(e.length === 0) {
            this.state.trainingId=""
            return
        }
        this.state.trainingId=e[0].id
        this.setState({isshow:false})
    }
    handleClientChanged = (e) => {
        if(e.length === 0) {
            this.state.clientId=""
            return
        }
        this.state.clientId=e[0].id
        this.setState({isshow:false})

    }
    handleSearchClick = async() => {

        this.setState({isloading:true})
        let submittedFrom = this.state.submittedDateFromPayroll ? moment(this.state.submittedDateFromPayroll).format('MM/DD/YYYY') : ""
        let submittedTo = this.state.submittedDateToPayroll ? moment(this.state.submittedDateToPayroll).format('MM/DD/YYYY') : ""
        const param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
            "ClearanceStatusId"     :   this.state.statusId,
            "FromDateCreated"       :   this.state.fromDateCreated,
            "ToDateCreated"         :   this.state.toDateCreated,
            "FromClearanceDate"     :   this.state.fromClearanceDate,
            "ToClearanceDate"       :   this.state.toClearanceDate,
            "FromDateReceive"       :   this.state.fromDateReceived,
            "ToDateReceive"         :   this.state.toDateReceived,
            "FromLastWorkingDate"   :   this.state.fromLastWorkingDate,
            "ToLastWorkingDate"     :   this.state.toLastWorkingDate,

            "SubmittedDateFromPayroll"   : submittedFrom,
            "SubmittedDateToPayroll"     : submittedTo,
            "ReviewStatusId"     :   "",
        };
        console.log("param")
        console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            const data = res.data.clearance;
            console.log("data search")
            console.log(data)
            if(this.state.reason === ""){
                this.setState({
                    trainingGrid : data.sort((a, b) => a.clearanceDateMember < b.clearanceDateMember ? 1 : -1),
                    isloading : false
                })
            }
            else {
                this.setState({
                    trainingGrid : data.sort((a, b) => a.clearanceDateMember < b.clearanceDateMember ? 1 : -1).filter(x => x.reasonForLeaving === this.state.reason),
                    isloading : false
                })
            }
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true,
                    trainingGrid    :   []
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    handleCreateChild = async() =>{
        let selectedData = this.state.trainingGrid.filter(x => x.isDeleted === "1")
        console.log("selectedData")
        console.log(selectedData)
        sessionStorage.setItem("selectedParent",JSON.stringify(selectedData) )
        this.setState({ navigateChild: true})
    }
    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.trainingGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disablebtn:disable})
    }
    handleEditClientClick = (row) =>{
        this.setState({openClientModal:true})
        this.child.initialize(row)
    }
    handleEditPositionClick = (row) =>{
        this.child1.initialize(row)
        this.setState({openPositionModal:true})
    }
    handleClientModalClose = (e) =>{
        this.setState({openClientModal:false})
    }
    handlePositionModalClose = (e) =>{
        this.setState({openPositionModal:false})
    }
    handleChangeResignationDate = date =>{
        this.setState({ resignationDate: date, isshow:false})
    }
    handleChangeClearanceDate = date =>{
        this.setState({ clearanceDate: date, isshow:false})
    }
    handleChangeSubmissionDate = date =>{
        this.setState({ submissionDate: date, isshow:false})
    }
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false})
    }
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false})
    }
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false})
    }
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false})
    }
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false})
    }
    handleChangeToDateSubmitted = date =>{
        this.setState({ submittedDateToPayroll: date, isshow:false})
    }
    handleChangeFromDateSubmitted = date =>{
        this.setState({ submittedDateFromPayroll: date, isshow:false})
    }
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false})
    }
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false})
    }
    handleChangeFromDateCreated = date =>{
        this.setState({ fromDateCreated: date, isshow:false})
    }
    handleChangeToDateCreated = date =>{
        this.setState({ toDateCreated: date, isshow:false})
    }
    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].clientId
        this.state.clientName   =   e[0].clientName
         this.setState({
             isshow:false,
         })
        this.GetEmployees();
    };
    handleChangeEmployee = (e) => {
        
        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.profileId    =   ""
            this.state.client       =   ""
            this.state.lastName     =   ""
            this.state.firstName    =   ""
            this.state.middleName   =   ""
            this.state.dateOfBirth  =   ""
            this.state.positionName =   ""
            this.state.contractDateStart  =   ""
            this.state.phicNo = ""
            this.state.sssNo = ""
            this.state.tinNo = ""
            this.state.pagibigNo = ""
            // return
        } 
        else {

            this.state.employeeId   =   e[0].id
            this.state.profileId    =   e[0].profileId
            this.state.client       =   e[0].clientName
            this.state.lastName     =   e[0].lastName
            this.state.firstName    =   e[0].firstName
            this.state.middleName   =   e[0].middleName
            this.state.dateOfBirth  =   e[0].dateOfBirth
            this.state.positionName =   e[0].position
            this.state.locationName =   e[0].locationName
            this.state.contractDateStart  =   e[0].contractDateStart
            this.state.phicNo       =   e[0].phicNumber
            this.state.sssNo        =   e[0].sssNumber
            this.state.tinNo        =   e[0].tinNumber
            this.state.pagibigNo    =   e[0].hdmfNumber
        }
        this.getEmployeeClearance();
    };
    getEmployeeClearance = async() =>{
        const param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "ProfileId"            :   this.state.userinfo.profileId,
            "DisengagementModeId"            :   this.state.disengagementModeId,
            "ClearanceDate"            :   this.state.clearanceDate,
            "ResignationDate"            :   this.state.resignationDate,
            "SubmissionDate"            :   this.state.submissionDate,
            "LastWorkingDate"            :   this.state.workingDate
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            const data = res.data.clearance;

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };


    handleChangedReason = (e) => {
        if (e.length === 0){
            this.setState({ reason : "" })
        } else {
            this.setState({ reason : e[0].name })
        }
    }
    handleChangeStatus = (e) =>{
        if (e.length === 0){
            this.state.statusId = ""
        } else {
            this.state.statusId = e[0].id
        }
    }
    handleChangedBatchNumber = (e) =>{
        this.setState({ batchNumber : e.target.value })
    }
    handleEditClick = (row)=>{
        // console.log("row")
        // console.log(row)
        sessionStorage.removeItem("clearanceeditdetails_")
        sessionStorage.setItem("clearanceeditdetails_" , JSON.stringify(row))
        this.setState({navigate: true})
    }


    GridExcellReports = async() =>{
        let newGridData = []
        let dataSelected = this.state.trainingGrid.filter( x=> x.isDeleted === "1")
        let _isSelected = []
        if(dataSelected.length === 0){
            _isSelected = this.state.trainingGrid
        }else{
            _isSelected = dataSelected
        }
        for (let i = 0; i < _isSelected.length; i++){
            let obj = {
                "BATCH NUMBER"              :   _isSelected[i]["batchNumber"],
                "EMPLOYEE NAME"             :   _isSelected[i]["employeeName"],
                "CLIENT NAME"               :   _isSelected[i]["clientName"],
                "REASON"                    :   _isSelected[i]["reasonForLeaving"],
                "BRANCH / LOCATION"         :   _isSelected[i]["locationName"],
                "DATE START"                :   _isSelected[i]["dateStart"],
                "LAST WORKING DATE"         :   _isSelected[i]["lastWorkingDateMember"],
                "CLEARANCE DATE"            :   _isSelected[i]["clearanceDateMember"],
                "STATUS"                    :   _isSelected[i]["clearanceStatus"],
                "DATE SUBMITTED BY ADMIN"   :   _isSelected[i]["dateUpdatedByServices"],
                "COMPLETION DATE"           :   _isSelected[i]["completionDate"],
                "CYCLE DAYS"                :   _isSelected[i]["cycleDays"],
                "FULL NAME"                 :   _isSelected[i]["createdByName"],
                "FULL NAME (UPDATEDBY )"    :   _isSelected[i]["modifiedByName"],
            }
            newGridData.push(obj)
        }
        console.log(newGridData)
        this.setState({gridDataExcel : newGridData})
    }
    render() {
        const { navigate } = this.state;
        if(navigate){
            return <Redirect to="/editclearance" push={true}/>
        }

        const { navigateChild } = this.state;
        if(navigateChild){
            return <Redirect to="/CreateChildClearance" push={true}/>
        };
        const clientCol = [
            {
                dataField: 'client',
                text: 'CLIENT',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            }
        ]
        const positionCol = [
            {
                dataField: 'position',
                text: 'POSITION',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            }
        ]
        const certificateCol = [
            {
                dataField: 'name',
                text: 'CERTIFICATE',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            }
        ]
        const { SearchBar, ClearSearchButton } = Search;
        const trainingColumn = [
            {
                dataField: 'batchNumber',
                text: 'BATCH NUMBER',
                editable: false,
                sort: true,
                headerStyle : () => {
                    return { width  : "5%" };
                }
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle : () => {
                    return { width  : "15%" };
                },
                searchable: false
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                searchable: false
            },
            {
                dataField: 'reasonForLeaving',
                text: 'REASON',
                editable: false,
                formatter   :   (cell, row, isSelect) => {
                    if(row.reasonForLeaving === "DOLE CASES"){
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "3%", color : "red"}}>
                                {row.reasonForLeaving}
                            </Form.Label>   
                        )
                    } 
                    else {
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "3%"}}>
                                {row.reasonForLeaving}
                            </Form.Label>  
                        )
                    }
                },
                headerStyle : () => {
                    return { width  : "5%" };
                },
                searchable: false
            },
            {
                dataField: 'locationName',
                text: 'BRANCH / LOCATION',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                searchable: false
            },
            {
                dataField: 'dateStart',
                text: 'DATE START',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%", textAlign : "center" };
                },
                style:{textAlign : "center"},
                searchable: false
            },
            {
                dataField: 'lastWorkingDateMember',
                text: 'LAST WORKING DATE',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%", textAlign : "center" };
                },
                style:{textAlign : "center"},
                searchable: false
            },
            {
                dataField: 'clearanceDateMember',
                text: 'clearance Date',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%", textAlign : "center" };
                },
                style:{textAlign : "center"},
                searchable: false
            },
            {
                dataField: 'clearanceStatus',
                text: 'STATUS',
                editable: false,
                formatter   :   (cell, row, isSelect) => {
                    if(row.clearanceStatus !== ""){
                        return (
                            <Form.Label style={{fontWeight : "bold", fontSize : "9", textAlign : "left"}}>
                                {row.clearanceStatus}
                            </Form.Label>   
                        )
                    }
                    else{
                        return (
                            <Form.Label style={{fontWeight : "bold", fontSize : "9", textAlign : "left"}}>
                                {row.clearanceStatus}
                            </Form.Label>   
                        )
                    } 
                },
                headerStyle : () => {
                    return { width  : "5%" };
                },
                searchable: false
            },
            {
                dataField: 'dateUpdatedByServices',
                text: 'Date submitted to are',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%", textAlign : "center" };
                },
                style:{textAlign : "center"},
                searchable: false
            },
            {
                dataField: 'dateSubmittedToPayroll',
                text: 'DATE SUBMITTED TO PAYROLL',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                searchable: false
            },
            {
                dataField: 'cycleDays',
                text: 'CYCLE DAYS',
                editable: false,
                headerStyle : () => {
                    return { width  : "2%" };
                },
                searchable: false
            },
            {
                dataField: 'createdByName',
                text: 'CREATED BY',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                searchable: false
            },
            {
                dataField: 'lastModifiedBy',
                text: 'MODIFIED BY',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                searchable: false
            },
            {
                dataField: 'completionDate',
                text: 'DATE COMPLETED',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%", textAlign : "center" };
                },
                style:{textAlign : "center"},
                searchable: false
            },
            // {
            //     dataField: 'dateClosed',
            //     text: 'DATE CLOSED',
            //     editable: false,
            //     headerStyle : () => {
            //         return { width  : "5%", textAlign : "center" };
            //     },
            //     style:{textAlign : "center"},
            //     searchable: false
            // },
            {
                dataField: 'isParent',
                text: 'PARENT',
                editable: false,
                formatter   :   (cell, row, isSelect) => {
                    if(row.parentId === ""){
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                                Parent
                            </Form.Label>   
                        )
                    } 
                    else{
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                                Child
                            </Form.Label>   
                        )
                    }  
                },
                headerStyle : () => {
                    return { width  : "3%" };
                },
                searchable: false
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable: false,
                headerStyle: () => {
                    return { width: "5%", };
                },
                formatter   :   (cell, row, isSelect) => {
                    if(row.clearanceStatus === "9"){
                        return
                    }else {
                        return (
                            <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} /* disabled = {row.clearanceStatus === "9" ? true : false} */
                                variant="link" onClick={e => this.handleEditClick(row)}
                            >View</Button>
                        )
                    }
                },
                searchable: false
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.trainingGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        // disable = item.isClearanceStatus === "10" ? false : true
                        // disable = isSelect ? false : true
                        // console.log(disable)
                        if(item.isClearanceStatus==="10" || item.isClearanceStatus==="9"){
                            disable = isSelect ? false : true
                            // console.log("status 10")
                        }
                    }
                })
                this.setState({disablebtn:disable})
            }
        };

        const selectClientRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const selectPositionRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const selectCertificateRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };

    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; VIEW / SEARCH </Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <div>
                                    <Accordion className="mt-2">
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                    CLICK TO SEARCH
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body> */}
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        CLIENT
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.client}
                                                                labelKey='clientName'
                                                                id="basic-example"
                                                                onChange={this.handleChangeClient}
                                                                options={this.state.trainingGrid}
                                                                placeholder='SELECT CLIENT's
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        EMPLOYEE
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.employee}
                                                                labelKey='employeeName'
                                                                id="basic-example"
                                                                onChange={this.handleChangeEmployee}
                                                                options={this.state.employeeClearance}
                                                                placeholder='SELECT EMPLOYEE'
                                                                //defaultSelected={[this.state.employeeName]}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        REASON FOR LEAVING
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.refReason}
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedReason}
                                                                options={this.state.reasonDDL}
                                                                placeholder='Select reason for leaving'
                                                                value = {this.state.reason}

                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            Status
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.name}
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeStatus}
                                                                options={this.state.statusDDL} 
                                                                placeholder='SELECT STATUS'
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Date Created
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.fromDateCreated}
                                                                onChange={this.handleChangeFromDateCreated}
                                                                value={this.props.fromDateCreated}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Date Created
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.toDateCreated}
                                                                onChange={this.handleChangeToDateCreated}
                                                                value={this.props.toDateCreated}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Clearance Date
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.fromClearanceDate}
                                                                onChange={this.handleChangeFromClearanceDate}
                                                                value={this.props.fromClearanceDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Clearance Date
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.toClearanceDate}
                                                                onChange={this.handleChangeToClearanceDate}
                                                                value={this.props.toClearanceDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Date Received
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.fromDateReceived}
                                                                onChange={this.handleChangeFromDateReceived}
                                                                value={this.props.fromDateReceived}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Date Received
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.toDateReceived}
                                                                onChange={this.handleChangeToDateReceived}
                                                                value={this.props.toDateReceived}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Last Working Date
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.fromLastWorkingDate}
                                                                onChange={this.handleChangeFromLastWorkingDate}
                                                                value={this.props.fromLastWorkingDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Last Working Date
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.toLastWorkingDate}
                                                                onChange={this.handleChangeToLastWorkingDate}
                                                                value={this.props.toLastWorkingDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Date Submitted to payroll
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.submittedDateFromPayroll}
                                                                onChange={this.handleChangeFromDateSubmitted}
                                                                value={this.props.fromDateReceived}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Date Submitted to payroll
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.submittedDateToPayroll}
                                                                onChange={this.handleChangeToDateSubmitted}
                                                                value={this.props.toDateReceived}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>                                
                                                    <Form.Group as={Row} controlId="formPlaintextEmail" className='mb-5'>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            RESiGNATION DATE
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='resignationDate'
                                                                selected={this.state.resignationDate}
                                                                onChange={this.handleChangeResignationDate}
                                                                minDate={this.minDate}
                                                                value={this.props.resignationDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT RESIGNATION DATE'
                                                                wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    {/* <div style={{height : "50px"}}></div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div> */}
                                <ButtonToolbar className="mt-3">
                                    <Button variant="primary" className="ml-auto noser-button-mr1 noser-button" onClick={ this.handleSearchClick }>
                                        Search
                                    </Button>
                                    <Button variant="info" className="noser-button" >
                                        <CSVLink style={{ color : "white" }} onClick ={this.GridExcellReports} filename= "Clearance.xls" data={this.state.gridDataExcel}>Export</CSVLink>
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-4">
                                    <ToolkitProvider
                                        keyField="id"
                                        data={ this.state.trainingGrid }
                                        columns={ trainingColumn }
                                        search
                                    >
                                    {
                                        props => (
                                        <div>                                 
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                BATCH NUMBER
                                            </Form.Label>
                                            <Col>
                                            <SearchBar { ...props.searchProps } />
                                            </Col>
                                        </Form.Group>
                                            <hr />
                                            <BootstrapTable
                                                { ...props.baseProps }
                                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                keyField = "id"
                                                data = { this.state.trainingGrid }
                                                columns = { trainingColumn }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                selectRow = { selectRow }
                                                //expandRow={ expandItems }
                                            />
                                        </div>
                                        )
                                    }
                                    </ToolkitProvider>
                                </div>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button-mr1" disabled={this.state.disablebtn} onClick={this.handleCreateChild}>CREATE CHILD</Button>
                                    <NavLink to="/clearance" >
                                        <Button className="noser-button" variant="primary"disabled={ this.state.userinfo.userId === "34007" ||this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "34024" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL03" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "TEAMLEAD02" || this.state.userinfo.firstName === "LDTEAMLEADO1" || this.state.userinfo.lastName === "ADMIN" || this.state.userinfo.employeeId === "91250" ? false : true} >
                                            Create new
                                        </Button>
                                    </NavLink>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <TrainingClient 
                    show={this.state.openClientModal}
                    onHide={this.handleClientModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                <TrainingPosition
                    show={this.state.openPositionModal}
                    onHide={this.handlePositionModalClose}
                    onRefModal={ref => (this.child1 = ref)}
                />
            </div> 
        )
    }

}

export  default Training 
