import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, DatePicker,
    Accordion, Tabs, Tab,
} 
from '../../noser-hris-component';
import {CSVLink, CSVDownload} from 'react-csv';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { GetRequest, PostRequest } from '../../noser.dataaccess'
let ref = React.createRef();

class ClearanceFinance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            openClientModal :   false,
            openPositionModal:  false,
            disabled        :   true,
            clientId        :   "",
            trainingId      :   "",
            trainingDDL     :   [],
            payrollDataArray    :   [],
            clientDDL       :   [],
            employeeDDL     :   [],
            requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}],
            gridDataExcel   :   [],
            isshowExpand    :   false,
            reasonDDL: [],
            reasonId: "",
            reason: "",
            reason: "",
            resignationDate: "",
            clearanceDate: "",
            submissionDate: "",
            workingDate: "",
            memberNotes:"",
            memberDDL:[],
            dateCreated: new Date(),
            dateCompleted: new Date(),
            dateSubmitted: "",
            clearanceStatus:"",
            // END MEMBER FIELDS


            //from edit details
            employeeNameTmp : "",
            employeeName:"",
            fromDateCreated : "",
            toDateCreated : "",
            fromClearanceDate:"",
            toClearanceDate:"",
            fromDateReceived:"",
            toDateReceived:"",
            fromLastWorkingDate:"",
            toLastWorkingDate:"",
            statusId : "",
            statusDDL : [],
            toLastWorkingDate : "",
            fromLastWorkingDate : "",
            toDateReceived : "",
            fromDateReceived :"", 
            toClearanceDate : "",
            fromClearanceDate : "",
            toDateCreated : "",
            fromDateCreated : "",
            datenow : new Date(),
            batchNumber : "",
            filteredBatch : [],
            selectedId : [],
            _tmpData : [],
            dataListArr:[],
            collectionArray:[],

            chargesArray : [],
            totalBalanceArr : [],
            payrollRemarks : "",
            financeRemarks : "",
            clearanceArray : [],
            dividendLst : [],
            financeDataList : [],
            bdoDataList : [],
            metroBankDataList : [],
            palawanListData : [],
            mlListData : [],
            ubListData : [],
            dateCompletedTmp : "",
            dateOfPaymentTmp : "",
            searchList : [],
            cleranceList : [],
            batchNumberTmp : "",
            selectbatchtmp : "",
            AreInlcusion : [],
            completionDateTmp : "",
            paycardDDL : [],
            paycardtypes : [],
            searchNames : [],
            lbListData  : [],
            gcashListData   : [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        // // // console.log(this.state.userinfo.userId)
        let sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetDividend()
        this.GetSubsidiaryLedger()

    };
    GetDividend = async() =>{
        // try{
        this.setState({ isloading: true })
        // console.log("getsl") 
        let _filterData = {"isDeleted" : "0", "isInclusion" : "1", "isSubmittedToPayroll" : "1", "isClearance" : "0"/* , "employeeId":"38909" */}
        let resp = await GetRequest({
            url : AppConfiguration.Setting().noserareapi + "action/get",
            params : {
                "dbname"      :   AppConfiguration.Setting().noseraredb,
                "_collection" : "InclusionLedger",
                "filter_data" : _filterData
            }    
        })
        // console.log("resp")
        // console.log(resp)

        if(resp.status==="1"){
            const data = resp.InclusionLedger
            this.setState({ dividendLst : resp.InclusionLedger,})
        } 
        else {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   resp.message,
                fade        :   true
            });
            return
        }
    }
    GetPayCardTypes = async()=> {
        // // // console.log('GetPayCardTypes')
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            // // // console.log('data.payCardTypes.filter(x=>x.id!=="3"&&x.id!=="5"&&x.id!=="7"&&x.id!=="8")')
            // // // console.log(data.payCardTypes.filter(x=>x.id!=="3"&&x.id!=="5"&&x.id!=="7"&&x.id!=="8"))
            let paycardTypeTmp = data.payCardTypes.filter(x=>x.id!=="3"&&x.id!=="5"&&x.id!=="7"&&x.id!=="8")
            let paycard_ = []
            paycardTypeTmp.forEach((itm,idx) => {
                paycard_.push({
                    value : itm.name,
                    label : itm.name,
                })
            })
            this.setState({paycardDDL:paycardTypeTmp,paycardtypes:paycard_});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    getGeneratedPayfiles= async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId, // --> session
            "UserId":this.state.userinfo.userId,
            "PayPeriodId":"",
            "ReferenceNumber":"", // --> clearance reference number,
            "IsClearance":"1" // --> default 1
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/GetPayrollFiles", params)
        .then(res => {
            const data = res.data
            // console.log("data")
            // console.log(data)
            this.setState({
                // bdoDataList         :   data.payrollFilesBDO.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber)),
                // metroBankDataList   :   data.payrollFilesMetroBank.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber)),
                // mlListData          :   data.payrollFilesML.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber)),
                // palawanListData     :   data.payrollFilesPalawan.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber)),
                // ubListData          :   data.payrollFilesUB.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber)),
                // isloading           :   false,
            })
            
            this.RemodelGridData(data)
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    RemodelGridData = async(itemList)=>{


        let payrollFilesBDO = itemList.payrollFilesBDO.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber))
        let payrollFilesML = itemList.payrollFilesML.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber))
        let payrollFilesMetroBank = itemList.payrollFilesMetroBank.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber))
        let payrollFilesPalawan = itemList.payrollFilesPalawan.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber))
        let payrollFilesUB = itemList.payrollFilesUB.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber))
        let payrollFilesLB = itemList.payrollFilesLB.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber))
        let payrollFilesGcash = itemList.payrollFilesGCash.filter(f => this.state.payrollDataArray.some(item => item.batchPayroll === f.referenceNumber))

        let newSetArray = ([...payrollFilesBDO, ...payrollFilesMetroBank,...payrollFilesML, ...payrollFilesPalawan,...payrollFilesUB, ...payrollFilesGcash, ...payrollFilesLB])

        payrollFilesBDO.forEach(itm =>{
            itm.payrollFileDetails.forEach(z =>{
                let paycardid = ""
                if(itm.payrollTypeId==="2"){
                    paycardid = "BDO"
                }
                else if(itm.payrollTypeId==="6"){
                    paycardid = "METROBANK"
                }
                else if(itm.payrollTypeId==="14"){
                    paycardid = "UNION BANK"
                }
                else if(itm.payrollTypeId==="4"){
                    paycardid = "M-LHUILLIER"
                }
                else if(itm.payrollTypeId==="15"){
                    paycardid = "PALAWAN"
                }
                z.completionDate = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                    this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                z.clientName =  this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                    this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                z.paycardType = this.state.paycardDDL.filter(x=> x.id===z.payCardTypeId).length>0 ? 
                                    this.state.paycardDDL.find(x=> x.id===z.payCardTypeId).name: ""
                z.paycardCredited = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                    this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).typeOfCashCardMember: ""
                z.isModified = "0"
                z.payrollId = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).id: ""
                                        
                // // // // console.log("z.completionDate")
                // // // // console.log(z.completionDate)
            })
        })

        payrollFilesML.forEach(itm =>{
            itm.payrollFileDetails.forEach(z =>{
                let paycardid = ""
                if(itm.payrollTypeId==="2"){
                    paycardid = "BDO"
                }
                else if(itm.payrollTypeId==="6"){
                    paycardid = "METROBANK"
                }
                else if(itm.payrollTypeId==="14"){
                    paycardid = "UNION BANK"
                }
                else if(itm.payrollTypeId==="4"){
                    paycardid = "M-LHUILLIER"
                }
                else if(itm.payrollTypeId==="15"){
                    paycardid = "PALAWAN"
                }
                z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                    this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                z.paycardType = this.state.paycardDDL.filter(x=> x.id===z.payCardTypeId).length>0 ? 
                                    this.state.paycardDDL.find(x=> x.id===z.payCardTypeId).name: ""
                z.paycardCredited = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                    this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).typeOfCashCardMember: ""
                z.isModified = "0"
                z.payrollId = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).id: ""
                                        
                // // // // console.log("z.completionDate")
                // // // // console.log(z.completionDate)
            })
        })

        payrollFilesMetroBank.forEach(itm =>{
            itm.payrollFileDetails.forEach(z =>{
                let paycardid = ""
                if(itm.payrollTypeId==="2"){
                    paycardid = "BDO"
                }
                else if(itm.payrollTypeId==="6"){
                    paycardid = "METROBANK"
                }
                else if(itm.payrollTypeId==="14"){
                    paycardid = "UNION BANK"
                }
                else if(itm.payrollTypeId==="4"){
                    paycardid = "M-LHUILLIER"
                }
                else if(itm.payrollTypeId==="15"){
                    paycardid = "PALAWAN"
                }
                z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                    this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                z.paycardType = this.state.paycardDDL.filter(x=> x.id===z.payCardTypeId).length>0 ? 
                                    this.state.paycardDDL.find(x=> x.id===z.payCardTypeId).name: ""
                z.paycardCredited = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                    this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).typeOfCashCardMember: ""
                z.isModified = "0"
                z.payrollId = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).id: ""
                // // // // console.log("z.completionDate")
                // // // // console.log(z.completionDate)
            })
        })

        payrollFilesPalawan.forEach(itm =>{
            itm.payrollFileDetails.forEach(z =>{
                let paycardid = ""
                if(itm.payrollTypeId==="2"){
                    paycardid = "BDO"
                }
                else if(itm.payrollTypeId==="6"){
                    paycardid = "METROBANK"
                }
                else if(itm.payrollTypeId==="14"){
                    paycardid = "UNION BANK"
                }
                else if(itm.payrollTypeId==="4"){
                    paycardid = "M-LHUILLIER"
                }
                else if(itm.payrollTypeId==="15"){
                    paycardid = "PALAWAN"
                }
                z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                z.paycardType = this.state.paycardDDL.filter(x=> x.id===z.payCardTypeId).length>0 ? 
                                this.state.paycardDDL.find(x=> x.id===z.payCardTypeId).name: ""
                z.paycardCredited = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).typeOfCashCardMember: ""
                z.isModified = "0"
                z.payrollId = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).id: ""
                // // // // console.log("z.completionDate")
                // // // // console.log(z.completionDate)
            })
        })

        payrollFilesUB.forEach(itm =>{
            itm.payrollFileDetails.forEach(z =>{
                let paycardid = ""
                if(itm.payrollTypeId==="2"){
                    paycardid = "BDO"
                }
                else if(itm.payrollTypeId==="6"){
                    paycardid = "METROBANK"
                }
                else if(itm.payrollTypeId==="14"){
                    paycardid = "UNION BANK"
                }
                else if(itm.payrollTypeId==="4"){
                    paycardid = "M-LHUILLIER"
                }
                else if(itm.payrollTypeId==="15"){
                    paycardid = "PALAWAN"
                }
                z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                z.paycardType = this.state.paycardDDL.filter(x=> x.id===z.payCardTypeId).length>0 ? 
                                this.state.paycardDDL.find(x=> x.id===z.payCardTypeId).name: ""
                z.paycardCredited = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).typeOfCashCardMember: ""
                z.isModified = "0"
                z.payrollId = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).id: ""
                // // console.log("z")
                // // console.log(z)
            })
        })

        payrollFilesLB.forEach(itm =>{
            itm.payrollFileDetails.forEach(z =>{
                let paycardid = ""
                if(itm.payrollTypeId==="2"){
                    paycardid = "BDO"
                }
                else if(itm.payrollTypeId==="6"){
                    paycardid = "METROBANK"
                }
                else if(itm.payrollTypeId==="14"){
                    paycardid = "UNION BANK"
                }
                else if(itm.payrollTypeId==="4"){
                    paycardid = "M-LHUILLIER"
                }
                else if(itm.payrollTypeId==="15"){
                    paycardid = "PALAWAN"
                }
                z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                z.paycardType = this.state.paycardDDL.filter(x=> x.id===z.payCardTypeId).length>0 ? 
                                this.state.paycardDDL.find(x=> x.id===z.payCardTypeId).name: ""
                z.paycardCredited = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).typeOfCashCardMember: ""
                z.isModified = "0"
                z.payrollId = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).id: ""
                // // console.log("z")
                // // console.log(z)
            })
        })

        payrollFilesGcash.forEach(itm =>{
            itm.payrollFileDetails.forEach(z =>{
                let paycardid = ""
                if(itm.payrollTypeId==="2"){
                    paycardid = "BDO"
                }
                else if(itm.payrollTypeId==="6"){
                    paycardid = "METROBANK"
                }
                else if(itm.payrollTypeId==="14"){
                    paycardid = "UNION BANK"
                }
                else if(itm.payrollTypeId==="4"){
                    paycardid = "M-LHUILLIER"
                }
                else if(itm.payrollTypeId==="15"){
                    paycardid = "PALAWAN"
                }
                z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                z.paycardType = this.state.paycardDDL.filter(x=> x.id===z.payCardTypeId).length>0 ? 
                                this.state.paycardDDL.find(x=> x.id===z.payCardTypeId).name: ""
                z.paycardCredited = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).typeOfCashCardMember: ""
                z.isModified = "0"
                z.payrollId = this.state.payrollDataArray.filter(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).length>0 ? 
                                this.state.payrollDataArray.find(x=> x.employeeId===z.employeeId && x.batchPayroll===itm.referenceNumber).id: ""
                // // console.log("z")
                // // console.log(z)
            })
        })
        // // console.log("payrollFilesBDO")
        // // console.log(payrollFilesBDO)
        // // // // console.log("this.state.payrollDataArray")
        // // // // console.log(this.state.payrollDataArray)

        let newArryFltr = newSetArray.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.batchNumber === thing.batchNumber
        )))
        let fltrNames = this.state.payrollDataArray.filter((thing, index, self) =>
        index === self.findIndex((t) => (
            t.employeeName === thing.employeeName
        )))
        
        this.setState({
            bdoDataList         :   payrollFilesBDO,
            metroBankDataList   :   payrollFilesMetroBank,
            mlListData          :   payrollFilesML,
            palawanListData     :   payrollFilesPalawan,
            ubListData          :   payrollFilesUB,
            lbListData          :   payrollFilesLB,
            gcashListData          :   payrollFilesGcash,
            isloading           :   false,
            searchList          :   newArryFltr,
            searchNames          :   fltrNames.sort((a, b) => a.employeeName < b.employeeName ? -1 : 1),
        })
        
    };
    GetClient = async() =>{
        this.setState({isloading:true})
        let params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            let data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,})
            }
            else{
                this.setState({clientDDL : data.clients,})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetDisengagementMode();
        this.GetDataGrid()
    };
    GetEmployees = () => {
    this.setState({isloading:true})
        let getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .then(res => {
            let data = res.data
            this.setState({
                employeeDDL        :   data.employees,
                isloading           :   false,
            }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
    
    };
    handleClientChanged = (e) => {
        if(e.length === 0) {
            this.state.clientId=""
            return
        }
        this.state.clientId=e[0].id
        this.setState({isshow:false})

    }
    handleClickSearch = async() => {
        // this.GetDataGrid()
        // this.state.payrollDataArray 
        // // // // console.log("this.state.batchNumberTmp")
        // // // // console.log(this.state.batchNumberTmp)
        this.setState({isloading:true})
        if(this.state.selectbatchtmp === "" && this.state.batchNumberTmp!==""){
            let payrollFilesBDO = this.state.bdoDataList.filter(f => f.batchNumber===this.state.batchNumberTmp)
            let payrollFilesML = this.state.mlListData.filter(f => f.batchNumber===this.state.batchNumberTmp)
            let payrollFilesMetroBank = this.state.metroBankDataList.filter(f => f.batchNumber===this.state.batchNumberTmp)
            let payrollFilesPalawan = this.state.palawanListData.filter(f => f.batchNumber===this.state.batchNumberTmp)
            let payrollFilesUB = this.state.ubListData.filter(f => f.batchNumber===this.state.batchNumberTmp)

            payrollFilesBDO.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                })
            })
            // // // // console.log("payrollFilesBDO")
            // // // // console.log(payrollFilesBDO)
    
            payrollFilesML.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                            this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                    // // // // // console.log("z.completionDate")
                    // // // // // console.log(z.completionDate)
                })
            })
            // // // // console.log("payrollFilesML")
            // // // // console.log(payrollFilesML)
    
            payrollFilesMetroBank.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                            this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                    // // // // // console.log("z.completionDate")
                    // // // // // console.log(z.completionDate)
                })
            })
            // // // // console.log("payrollFilesMetroBank")
            // // // // console.log(payrollFilesMetroBank)
    
            payrollFilesPalawan.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                            this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                    this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                    // // // // // console.log("z.completionDate")
                    // // // // // console.log(z.completionDate)
                })
            })
            // // // // console.log("payrollFilesPalawan")
            // // // // console.log(payrollFilesPalawan)
    
            payrollFilesUB.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                            this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                    this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                    // // // // // console.log("z.completionDate")
                    // // // // // console.log(z.completionDate)
                })
            })
            // // // // console.log("payrollFilesUB")
            // // // // console.log(payrollFilesUB)
    
    
            // // // // // console.log("payrollFilesBDO")
            // // // // // console.log(payrollFilesBDO)
            // // // // // console.log("this.state.cleranceList")
            // // // // // console.log(this.state.cleranceList)
            // let newSetArray = ([...payrollFilesBDO, ...payrollFilesMetroBank,...payrollFilesML, ...payrollFilesPalawan,...payrollFilesUB,...this.state.cleranceList])
            // // // // // console.log("newSetArray")
            // // // // // console.log(newSetArray)
            
            this.setState({
                bdoDataList         :   payrollFilesBDO,
                metroBankDataList   :   payrollFilesMetroBank,
                mlListData          :   payrollFilesML,
                palawanListData     :   payrollFilesPalawan,
                ubListData          :   payrollFilesUB,
                isloading           :   false,
                payrollDataArray    :   [],
                batchNumberTmp      :   "",
            })
        }
        else if(this.state.selectbatchtmp !== "" && this.state.batchNumberTmp===""){
            let payrollFilesBDO = this.state.bdoDataList.filter(f => f.referenceNumber===this.state.selectbatchtmp)
            let payrollFilesML = this.state.mlListData.filter(f => f.referenceNumber===this.state.selectbatchtmp)
            let payrollFilesMetroBank = this.state.metroBankDataList.filter(f => f.referenceNumber===this.state.selectbatchtmp)
            let payrollFilesPalawan = this.state.palawanListData.filter(f => f.referenceNumber===this.state.selectbatchtmp)
            let payrollFilesUB = this.state.ubListData.filter(f => f.referenceNumber===this.state.selectbatchtmp)

            payrollFilesBDO.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                })
            })
            // // // // console.log("payrollFilesBDO")
            // // // // console.log(payrollFilesBDO)
    
            payrollFilesML.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                            this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                    // // // // // console.log("z.completionDate")
                    // // // // // console.log(z.completionDate)
                })
            })
            // // // // console.log("payrollFilesML")
            // // // // console.log(payrollFilesML)
    
            payrollFilesMetroBank.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                            this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                        this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                    // // // // // console.log("z.completionDate")
                    // // // // // console.log(z.completionDate)
                })
            })
            // // // // console.log("payrollFilesMetroBank")
            // // // // console.log(payrollFilesMetroBank)
    
            payrollFilesPalawan.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                            this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                    this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                    // // // // // console.log("z.completionDate")
                    // // // // // console.log(z.completionDate)
                })
            })
            // // // // console.log("payrollFilesPalawan")
            // // // // console.log(payrollFilesPalawan)
    
            payrollFilesUB.forEach(itm =>{
                itm.payrollFileDetails.forEach(z =>{
                    z.completionDate   = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                            this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).completionDate: ""
                    z.clientName = this.state.payrollDataArray.filter(x=>x.employeeId===z.employeeId).length>0 ? 
                                    this.state.payrollDataArray.find(x=>x.employeeId===z.employeeId).clientName: ""
                                            
                    // // // // // console.log("z.completionDate")
                    // // // // // console.log(z.completionDate)
                })
            })
            // // // // console.log("payrollFilesUB")
            // // // // console.log(payrollFilesUB)
    
    
            // // // // // console.log("payrollFilesBDO")
            // // // // // console.log(payrollFilesBDO)
            // // // // // console.log("this.state.cleranceList")
            // // // // // console.log(this.state.cleranceList)
            // let newSetArray = ([...payrollFilesBDO, ...payrollFilesMetroBank,...payrollFilesML, ...payrollFilesPalawan,...payrollFilesUB,...this.state.cleranceList])
            // // // // // console.log("newSetArray")
            // // // // // console.log(newSetArray)
            
            this.setState({
                bdoDataList         :   payrollFilesBDO,
                metroBankDataList   :   payrollFilesMetroBank,
                mlListData          :   payrollFilesML,
                palawanListData     :   payrollFilesPalawan,
                ubListData          :   payrollFilesUB,
                isloading           :   false,
                payrollDataArray    :   this.state.payrollDataArray.filter(x=>x.batchPayroll === this.state.selectbatchtmp),
                selectbatchtmp      :   "",
            })
        }
        else if(this.state.selectbatchtmp === "" && this.state.batchNumberTmp==="" && this.state.fromDateCreated !=="" && this.state.toDateCreated !== ""){

            let filterDateCOmpleted = this.state.payrollDataArray.filter(x=>x.completionDate!=="")
            // console.log(filterDateCOmpleted)
            // console.log(filterDateCOmpleted)
            let Test = filterDateCOmpleted.filter( x => parseFloat(moment(x.completionDate).format("MMDDYYYY")) >= parseFloat(moment(this.state.fromDateCreated).format("MMDDYYYY")) && parseFloat(moment(x.completionDate).format("MMDDYYYY")) <= parseFloat(moment(this.state.toDateCreated).format("MMDDYYYY")))
            // console.log(Test)
            // console.log(Test)

            let payrollFilesBDO = this.state.bdoDataList.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))
            let payrollFilesML = this.state.mlListData.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))
            let payrollFilesMetroBank = this.state.metroBankDataList.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))
            let payrollFilesPalawan = this.state.palawanListData.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))
            let payrollFilesUB = this.state.ubListData.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))

            payrollFilesBDO.forEach(itm =>{
                // // // // console.log("itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId)")
                // // // // console.log(itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId))
                // itm.payrollFileDetails = itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId)
                itm.payrollFileDetails = itm.payrollFileDetails.filter(f => Test.some(item => item.employeeid === f.employeeid))
            })
    
            payrollFilesML.forEach(itm =>{
                // // // // console.log("itm.payrollFileDetails")
                // // // // console.log(itm.payrollFileDetails)
                itm.payrollFileDetails = itm.payrollFileDetails.filter(f => Test.some(item => item.employeeid === f.employeeid))
            })
            // // // // console.log("payrollFilesML")
            // // // // console.log(payrollFilesML)
    
            payrollFilesMetroBank.forEach(itm =>{
                itm.payrollFileDetails = itm.payrollFileDetails.filter(f => Test.some(item => item.employeeid === f.employeeid))
            })
            // // // // console.log("payrollFilesMetroBank")
            // // // // console.log(payrollFilesMetroBank)
    
            payrollFilesPalawan.forEach(itm =>{
                itm.payrollFileDetails = itm.payrollFileDetails.filter(f => Test.some(item => item.employeeid === f.employeeid))
            })
            // // // // console.log("payrollFilesPalawan")
            // // // // console.log(payrollFilesPalawan)
    
            payrollFilesUB.forEach(itm =>{
                itm.payrollFileDetails = itm.payrollFileDetails.filter(f => Test.some(item => item.employeeid === f.employeeid))
            })
            
            // let testTmp = payrollFilesBDO.filter(x=>x.payrollFileDetails.length>0)
            this.setState({
                bdoDataList         :   payrollFilesBDO.filter(x=>x.payrollFileDetails.length>0),
                metroBankDataList   :   payrollFilesMetroBank.filter(x=>x.payrollFileDetails.length>0),
                mlListData          :   payrollFilesML.filter(x=>x.payrollFileDetails.length>0),
                palawanListData     :   payrollFilesPalawan.filter(x=>x.payrollFileDetails.length>0),
                ubListData          :   payrollFilesUB.filter(x=>x.payrollFileDetails.length>0),
                isloading           :   false,
                payrollDataArray    :   this.state.payrollDataArray.filter( x => parseFloat(moment(x.completionDate).format("MMDDYYYY")) >= parseFloat(moment(this.state.fromDateCreated).format("MMDDYYYY")) && parseFloat(moment(x.completionDate).format("MMDDYYYY")) <= parseFloat(moment(this.state.toDateCreated).format("MMDDYYYY"))),
                batchNumberTmp      :   "",
            })
        }
        else if(this.state.selectedEmpTmp !== "" &&this.state.selectbatchtmp === "" && this.state.batchNumberTmp==="" && this.state.fromDateCreated ==="" && this.state.toDateCreated === ""){
            let Test = this.state.payrollDataArray.filter(x=>x.employeeName===this.state.employeeNameTmp)
            // // // console.log("Test")
            // // // console.log(Test)
            
            let payrollFilesBDO = this.state.bdoDataList.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))
            let payrollFilesML = this.state.mlListData.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))
            let payrollFilesMetroBank = this.state.metroBankDataList.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))
            let payrollFilesPalawan = this.state.palawanListData.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))
            let payrollFilesUB = this.state.ubListData.filter(f => Test.some(item => item.batchPayroll === f.referenceNumber))

            payrollFilesBDO.forEach(itm =>{
                // // // // console.log("itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId)")
                // // // // console.log(itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId))
                itm.payrollFileDetails = itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId)
            })
    
            payrollFilesML.forEach(itm =>{
                // // // // console.log("itm.payrollFileDetails")
                // // // // console.log(itm.payrollFileDetails)
                itm.payrollFileDetails = itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId)
            })
            // // // // console.log("payrollFilesML")
            // // // // console.log(payrollFilesML)
    
            payrollFilesMetroBank.forEach(itm =>{
                itm.payrollFileDetails = itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId)
            })
            // // // // console.log("payrollFilesMetroBank")
            // // // // console.log(payrollFilesMetroBank)
    
            payrollFilesPalawan.forEach(itm =>{
                itm.payrollFileDetails = itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId)
            })
            // // // // console.log("payrollFilesPalawan")
            // // // // console.log(payrollFilesPalawan)
    
            payrollFilesUB.forEach(itm =>{
                itm.payrollFileDetails = itm.payrollFileDetails.filter(x=>x.employeeId===Test[0].employeeId)
            })
            
            // let testTmp = payrollFilesBDO.filter(x=>x.payrollFileDetails.length>0)
            this.setState({
                bdoDataList         :   payrollFilesBDO.filter(x=>x.payrollFileDetails.length>0),
                metroBankDataList   :   payrollFilesMetroBank.filter(x=>x.payrollFileDetails.length>0),
                mlListData          :   payrollFilesML.filter(x=>x.payrollFileDetails.length>0),
                palawanListData     :   payrollFilesPalawan.filter(x=>x.payrollFileDetails.length>0),
                ubListData          :   payrollFilesUB.filter(x=>x.payrollFileDetails.length>0),
                isloading           :   false,
                payrollDataArray    :   this.state.payrollDataArray.filter(x=>x.employeeName===this.state.employeeNameTmp),
                batchNumberTmp      :   "",
            })
        }
        
    }
    GetSubsidiaryLedger = async() =>{  
        this.setState({ isloading: true})  
        let _filterData = {"isDeleted" : "0", "isInclusion" : "1", "isSubmittedToPayroll" : "1", "isClearance" : "0"}
        let resp = await GetRequest({
            url : AppConfiguration.Setting().noserareapi + "action/get",
            params : {
                        "dbname"      :   AppConfiguration.Setting().noseraredb,
                        "_collection" : "InclusionLedger",
                        "filter_data" : _filterData
                    }    
        })
        if(resp.status==="1"){
            const data = resp.ResultList
            // // console.log("SubsidiaryLedgerStaging")
            // // console.log(data)
            // console.log("resp")
            // console.log(resp)
            this.setState({ AreInlcusion: resp.ResultList})
            
        } 
        else{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   resp.message,
                fade        :   true
            });
            return
        }
        this.GetClient()
        this.getStatusClearance()
        this.GetPayCardTypes()
    };
    GetDataGrid = async() => {
       
        this.setState({isloading:true})
        let param = {
        "IpAddress"         :   "0.0.0.0",
        "ClientId"          :   "",
        "UserId"            :   "35954",
        "ProfileId"            :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;          
            let filterData = data.filter( x => parseFloat(x.isClearanceStatus) >= 8 && x.isFinalized ==="1" )
            // // console.log("filterData")
            // // console.log(filterData.filter(x=>x.employeeNo === "20242-0132149"))
            let _filteredBatch = filterData.filter(x=>x.batchNumber!== ""&&x.netPay>0)
            let _BatchNumberDDL = _filteredBatch.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.batchPayroll === thing.batchPayroll
                ))
            )
            if(data.length > 0) {
                this.setState({
                    payrollDataArray : filterData.filter(x=>parseFloat(String(x.netPay).replace(',','')) > 0).sort((a, b) => new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1),
                    cleranceList : _BatchNumberDDL.sort((a, b) => a.batchPayroll > b.batchPayroll ? -1 : 1),
                    clearanceArray : filterData.filter(x=>x.netPay>0).sort((a, b) => a.employeeName < b.employeeName ? -1 : 1),
                    isshow: false, 
                })
            }
            // this.setState({isloading:false});
            
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            
            this.GetPayrollEmployee(filterData)
            this.getGeneratedPayfiles()
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetPayrollEmployee = async(ItemList) =>{
        // // // // // // // // console.log("ItemList")
        // // // // // // // // console.log(ItemList)
        let dataTmp = []
        for ( let i = 0; i < ItemList.length; i++ ) {
            let payrollParams = {
                "IpAddress":"0.0.0.0",
                "ClientId":ItemList[i].clientId,
                "UserId":this.state.userinfo.userId,
                "PayPeriodId":ItemList[i].payPeriodId,
                "EmployeeId":ItemList[i].employeeId,
                "IsProcessed":"1",
                "IsOffCycle":"0"
            };
            await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetEmployeePayrolls", payrollParams)
            .then(res => {
                let data = res.data;
            })
            .catch(error=>{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        }
    };
    getStatusClearance = async() =>{
        let statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0040"
        };
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
        .then(res => {
            let data = res.data;
            this.setState({
                statusDDL : res.data.dataReferences
            })
                
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetDisengagementMode = async() => {
        this.setState({isloading:true})
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            let data = res.data.disengagementMode;
            this.setState({
                reasonDDL: data,
            });

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };


    GridDataModified(oldValue, newValue, id, column,) {
        let disable = true
        this.state.financeDataList.forEach(item => {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
            }
        })
        this.setState({disablebtn:disable})
    };
    // GridDataModifiedSubCol(oldValue, newValue, id, column) {
    //     this.state.collectionArray.map(function(item,i) {
    //         if (item.id===id){
    //             item.isModified = newValue!=oldValue ? "1" : "0"
    //             item.remarks = newValue
    //         }
    //     })
    // };








    GridExcellReports = () =>{
        let newGridData = []
        let dataSelected = this.state.payrollDataArray.filter( x=> x.isDeleted === "1")
        let _isSelected = []
        if(dataSelected.length === 0){
            _isSelected = this.state.payrollDataArray
        }else{
            _isSelected = dataSelected
        }
        for (let i = 0; i < _isSelected.length; i++){
            let obj = {
                "EMPLOYEE NAME"         :   _isSelected[i]["employeeName"],
                "CLIENT NAME"           :   _isSelected[i]["clientName"],
                "BRANCH / LOCATION"     :   _isSelected[i]["locationName"],
                "DATE START"            :   _isSelected[i]["dateStart"],
                "LAST WORKING DATE"     :   _isSelected[i]["lastWorkingDateMember"],
                "CLEARANCE DATE"        :   _isSelected[i]["clearanceDateMember"],
                "STATUS"                :   _isSelected[i]["clearanceStatus"],
                "COMPLETION DATE"       :   _isSelected[i][""],
                "CYCLE DAYS"            :   _isSelected[i]["cycleDays"],
                "FULL NAME"             :   _isSelected[i]["createdByName"],
                "FULL NAME (UPDATEDBY )" :   _isSelected[i]["modifiedByName"],
            }
            newGridData.push(obj)
        }
        this.setState({gridDataExcel : newGridData})
    }

    /* Romel */

    
    
    handleChangeResignationDate = date =>{
        this.setState({ resignationDate: date, isshow:false , })
    }
    handleChangeClearanceDate = date =>{
        this.setState({ clearanceDate: date, isshow:false , })
    }
    handleChangeSubmissionDate = date =>{
        this.setState({ submissionDate: date, isshow:false , })
    }
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false , })
    }
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false , })
    }
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false , })
    }
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false , })
    }
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false , })
    }
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false , })
    }
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false , })
    }
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false , })
    }
    handleChangeFromDateCreated = date =>{
        this.setState({ fromDateCreated: date, isshow:false , })
    }
    handleChangeToDateCreated = date =>{
        this.setState({ toDateCreated: date, isshow:false , })
    }
    handleChangeClient = (e) => {
        if(e.length == 0) {
            // this.state.clientId     =   ""
            this.state.selectbatchtmp   =   ""
            return
        }
        // this.state.clientId     =   e[0].id
        this.state.selectbatchtmp   =   e[0].batchPayroll
         this.setState({
            isshow:false,
            
         })
        // this.GetEmployees();
        // this.refBatch.current.clear()
    };
    handleChangedBatchNumber = (e) =>{
        if(e.length == 0) {
            this.state.batchNumber   =   ""
            this.setState({ payrollDataArray : this.state._tmpData, selectedId : [], batchNumber : "" })
        } else{
            this.state.batchNumber   =   e[0].name
            this.setState({
                isshow:false,
            })
        }
        if (e.length !== 0){
            this.Selectbatch(e[0].batchNumber)
        }
    }
    handleChangeEmployee = (e) => {
        
        if(e.length === 0) {
            this.state.batchNumberTmp=""
            
        } 
        else{
            this.state.batchNumberTmp=e[0].batchNumber
        }
        // // // // // console.log(e)
    };
    handleChangeEmployeeName = (e) =>{
        if(e.length === 0) {
            this.state.employeeNameTmp=""
        
        } 
        else{
            this.state.employeeNameTmp=e[0].employeeName
        }
    }
    getEmployeeClearance = async() =>{
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.userinfo.clientId,
            "ProfileId"             :   this.state.userinfo.profileId,
            "DisengagementModeId"   :   this.state.disengagementModeId,
            "ClearanceDate"         :   this.state.clearanceDate,
            "ResignationDate"       :   this.state.resignationDate,
            "SubmissionDate"        :   this.state.submissionDate,
            "LastWorkingDate"       :   this.state.workingDate,
            "ReasonForLeaving"      :   this.state.reason
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    handleChangedReason = (e) => {
        if (e.length === 0){
            this.setState({ reason : "" })
        } else {
            this.setState({ reason : e[0].name })
        }
    }
    handleClickRefresh = () =>{
        window.location.reload(true)
    }
    handleClickDelete = async() =>{
        this.setState({isloading : true })
        let param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "payrollFiles":[
                {
                    "Id" : "",
                    "IsClearance":"1" // --> default 1
                }
            ]
        };
        await axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Accounting/DeletePayrollFiles",  param
        )
            .then(res => {
            let data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
            this.GetDataGrid();
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleClickReject = async() =>{
        this.setState({isloading:true})
        // let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        // let dateCompletedTmp = moment(this.state.dateCompletedTmp) ? moment(this.state.dateCompletedTmp).format('MM/DD/YYYY') : ""
        // if(dateCompletedTmp===""){
        //     this.setState({
        //         isloading       :   false,
        //         alerttype       :   "Error!",
        //         isshow          :   true,
        //         color           :   "danger",
        //         message         :    "Please select date completed.",
        //         fade            :   true
        //     })
        //     return
        // }
        // let dateOfPayment = (this.state.dateOfPaymentTmp) ? moment(this.state.dateOfPaymentTmp).format('MM/DD/YYYY') : ""
        // if(dateOfPayment===""){
        //     this.setState({
        //         isloading       :   false,
        //         alerttype       :   "Error!",
        //         isshow          :   true,
        //         color           :   "danger",
        //         message         :    "Please select date of payment.",
        //         fade            :   true
        //     })
        //     return
        // }
        let arrLst = []
        let newArry = []
        let bdoArry = []
        let mlArry = []
        let mbArry = []
        let palArry = []
        let ubArry = []

        let bdoTmp = this.state.bdoDataList/* .filter(x=>x.isDeleted==="1") */
        bdoTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                bdoArry.push({
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    // isDeleted : itm.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                    isDeleted : x.isDeleted,
                    referenceNumber : itm.referenceNumber
                })
            })
        })
        // // console.log("bdoArry")
        // // console.log(bdoArry)
        let metroTmp = this.state.metroBankDataList/* .filter(x=>x.isDeleted==="1") */
        metroTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                mbArry.push({
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    isModified : itm.isModified,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    referenceNumber : itm.referenceNumber
                })
            })
        })
        // // // // // console.log("mbArry")
        // // // // // console.log(mbArry)
        let mlTmp = this.state.mlListData/* .filter(x=>x.isDeleted==="1") */
        mlTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                mlArry.push({
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    isModified : itm.isModified,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    referenceNumber : itm.referenceNumber
                })
            })
        })
        // // // // // console.log("mlArry")
        // // // // // console.log(mlArry)
        let palawanTmp = this.state.palawanListData/* .filter(x=>x.isDeleted==="1") */
        palawanTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                palArry.push({
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                    referenceNumber : itm.referenceNumber
                })
            })
        })
        // // // // // console.log("palArry")
        // // // // // console.log(palArry)
        let ubTmp = this.state.ubListData/* .filter(x=>x.isDeleted==="1") */
        ubTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                ubArry.push({
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                    referenceNumber : itm.referenceNumber
                })
            })
        })
        // // // // // console.log("ubArry")
        // // // // // console.log(ubArry)
        let financeTmp = this.state.payrollDataArray
        financeTmp.forEach(itm => {
            newArry.push({
                employeeId : itm.employeeId,
                transactionDate : itm.dateCreated,
                isDeleted : itm.isDeleted,
                completionDate : itm.completionDate,
                isModified : itm.isModified,
                // referenceNumber : x.referenceNumber
                // payCardNumber: itm.payCardNumber,
                // paycardType: itm.paycardType,

            })
        })
        // let filterDate = ([...bdoArry, ...mbArry,...mlArry, ...palArry,...ubArry,...newArry])
        let newSetArray = ([...bdoArry, ...mbArry,...mlArry, ...palArry,...ubArry,...newArry])
        
        // // console.log("bdoArry")
        // // console.log(bdoArry)

        let selectedData_ = newSetArray.filter( x => x.isDeleted === "1" || x.isModified ==="1")
        // console.log("selectedData_")
        // console.log(selectedData_) 
        
        let validateSelected = newSetArray.filter( x => x.isDeleted === "1")
        // // // // // console.log("validateSelected")
        // // // // // console.log(validateSelected)
        if(validateSelected.length===0){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select atleast 1 data to process",
                fade            :   true,
            })
            // return newSetArray = []
        }
        // selectedData_ .forEach((itm) => {
        //     if(moment(itm.transactionDate).format("MM/DD/YYYY") > moment(this.state.dateCompletedTmp).format("MM/DD/YYYY")){
        //         this.setState({
        //             isloading       :   false,
        //             alerttype       :   "Error!",
        //             isshow          :   true,
        //             color           :   "danger",
        //             message         :    "Invalid selection of Date Completed.",
        //             fade            :   true,
        //         })
        //         return newSetArray = []
        //     }
        // })
        let _fltrAttchment = this.state.payrollDataArray.filter(f => selectedData_.some(item => item.employeeId === f.employeeId && f.completionDate==="" ))
        // console.log("_fltrAttchment")
        // console.log(_fltrAttchment)
        // if(moment(this.state.dateCompletedTmp).format("MM/DD/YYYY") > moment(date).format("MM/DD/YYYY")){
        //     this.setState({
        //         isloading       :   false,
        //         alerttype       :   "Error!",
        //         isshow          :   true,
        //         color           :   "danger",
        //         message         :    "Invalid selection of Date Completed. (future date)",
        //         fade            :   true,
        //     })
        //     return newSetArray = []
        // }
        // else {
        _fltrAttchment.filter(f => selectedData_.some(item => item.referenceNumber === f.batchPayroll && f.completionDate==="" )).forEach(itm => {
            let _userId = this.state.userinfo.userId
            let paycardType_ = selectedData_.find(x=>x.employeeId===itm.employeeId).paycardType
            let paycardid = ""
            let cashcardNo = ""
            // if(paycardType_==="BDO"){
            //     paycardid = "2"
            //     cashcardNo = itm.cashCardNumberMember
            // }
            // else if(paycardType_==="METROBANK"){
            //     paycardid = "6"
            //     cashcardNo = itm.cashCardNumberMember
            // }
            // else if(paycardType_==="UNION BANK"){
            //     paycardid = "14"
            //     cashcardNo = itm.cashCardNumberMember
            // }
            // else if(paycardType_==="M-LHUILLIER"){
            //     paycardid = "4"
            //     cashcardNo = ""
            // }
            // else if(paycardType_==="PALAWAN"){
            //     paycardid = "15"
            //     cashcardNo = ""
            // }
            // else { 
            //     // console.log("eeror on paycard id")
            // }
            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":paycardType_,
                "CashCardNumberMember":itm.cashCardNumberMember,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,
                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isCheckedByRmt,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": itm.isClearanceStatus,
                "clearanceDetails" :itm.clearanceDetails,
                    
                /* ADDITIONAL */
                "DateUpdatedByClient": itm.dateUpdatedByClient,
                "UpdatedByClient":_userId,

                "DateUpdatedByLRD":itm.dateUpdatedByLRD,
                "UpdatedByLRD":_userId,

                "DateUpdatedByRMT":itm.dateUpdatedByRMT,
                "UpdatedByRMT":_userId,

                "DateUpdatedByServices":itm.dateUpdatedByServices,
                "UpdatedByServices":_userId,

                "DateUpdatedByARE":itm.dateUpdatedByARE,
                "UpdatedByARE":itm.isUpdatedByARE,

                "DateUpdatedByFinance":itm.dateUpdatedByFinance,
                "UpdatedByFinance":_userId,
                "DateSubmittedByStaff":itm.dateSubmittedByStaff,
                "SubmittedByStaff": _userId,
                "DateSubmittedByLead":itm.dateSubmittedByLead,
                "SubmittedByLead":_userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":_userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":_userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":_userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":_userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":_userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":_userId,
                
                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":itm.clearanceDeductions,

                
                // 21/07/2023
                "ParamountId" : itm.paramountId,
                "LastSalary" : itm.lastSalary,
                "Incentives" : itm.incentives,
                "Sil" : itm.sil,
                "ThirteenthMonth" : itm.thirteenthMonth,
                "Inclusions" : itm.inclusions,
                "GrossPay" : itm.grossPay,
                "NetPay" : itm.netPay,
                "totalBalance" : itm.totalBalance,
                "PeriodCovered" : itm.periodCovered,
                "IsModifiedByARE" : "0",
                "SeparationPay" : itm.separationPay,
                // "ReviewStatus" : "0",
                "DateReturnedByPayroll" : "",
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                // "IsClearedSubmit" : "0",
                "IsGenerated" : itm.isGenerated,
                "IsFinalized" : itm.isFinalized,
                "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                'BatchPayroll' : itm.batchPayroll,
                "Adjustment" : typeof itm.adjustment === "undefined" ? "" : itm.adjustment,
                "OtherPayroll" : typeof itm.otherPayroll === "undefined" ? "" : itm.otherPayroll,
                "Cbu" : typeof itm.cbu === "undefined" ? "" : itm.cbu,
                "Dividend" : typeof itm.dividend === "undefined" ? "" : itm.dividend,
                "TotalInclusion" : itm.totalInclusion,
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                "SettlementFee" : itm.settlementFee,
                "CompletionDate" : (itm.CompletionDate) ? moment(itm.CompletionDate).format('MM/DD/YYYY') : "",
                "DateOfPayment" : (itm.DateOfPayment) ? moment(itm.DateOfPayment).format('MM/DD/YYYY') : "",
                "FinancePayoutDate" : (itm.FinancePayoutDate) ? moment(itm.FinancePayoutDate).format('MM/DD/YYYY') : "",
                "DateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                "ReviewStatus" : "2",
                "IsClearedSubmit" : "1",
                "IsAre" : "1",
                "IsPayroll" : "1",
                "LastModifiedBy" : this.state.userinfo.fullName
                /* END */
                
            })
            this.setState({ profileId : itm.employeeNo })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst
        }
        // console.log("reject param")
        // console.log(param)
    
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data; 
            // console.log("data")
            // console.log(data)
            if(res.data.status==="1"){
                this.GetDataGrid();
                this.setState({
                    dateCompletedTmp : "",
                    dateOfPaymentTmp : "",
                    isshow          :   true,
                    alerttype       :   "Sucess!",
                    color           :   "success",
                    message         :    data.message,
                    isloading   :   false,
                })
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleClickClose = async() => {
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let dateCompletedTmp = moment(this.state.dateCompletedTmp) ? moment(this.state.dateCompletedTmp).format('MM/DD/YYYY') : ""
        if(dateCompletedTmp===""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select date completed.",
                fade            :   true
            })
            return
        }
        let dateOfPayment = (this.state.dateOfPaymentTmp) ? moment(this.state.dateOfPaymentTmp).format('MM/DD/YYYY') : ""
        if(dateOfPayment===""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select date of payment.",
                fade            :   true
            })
            return
        }
        let arrLst = []
        let newArry = []
        let bdoArry = []
        let mlArry = []
        let mbArry = []
        let palArry = []
        let ubArry = []
        let lbArry = []
        let gcashArry = []

        let bdoTmp = this.state.bdoDataList/* .filter(x=>x.isDeleted==="1") */
        bdoTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                bdoArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    // isDeleted : itm.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                    isDeleted : x.isDeleted,
                })
            })
        })
        // // console.log("bdoArry")
        // // console.log(bdoArry)
        let metroTmp = this.state.metroBankDataList/* .filter(x=>x.isDeleted==="1") */
        metroTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                mbArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    isModified : itm.isModified,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber
                })
            })
        })
        // // // // // console.log("mbArry")
        // // // // // console.log(mbArry)
        let mlTmp = this.state.mlListData/* .filter(x=>x.isDeleted==="1") */
        mlTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                mlArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    isModified : itm.isModified,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber
                })
            })
        })
        // // // // // console.log("mlArry")
        // // // // // console.log(mlArry)
        let palawanTmp = this.state.palawanListData/* .filter(x=>x.isDeleted==="1") */
        palawanTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                palArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                })
            })
        })
        // // // // // console.log("palArry")
        // // // // // console.log(palArry)
        let ubTmp = this.state.ubListData/* .filter(x=>x.isDeleted==="1") */
        ubTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                ubArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                })
            })
        })
        let lbTmp = this.state.lbListData/* .filter(x=>x.isDeleted==="1") */
        lbTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                lbArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                })
            })
        })
        let gcashTmp = this.state.gcashListData/* .filter(x=>x.isDeleted==="1") */
        // // console.log("gcashTmp")
        // // console.log(gcashTmp)
        gcashTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                gcashArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                })
            })
        })
        // // // // // console.log("ubArry")
        // // // // // console.log(ubArry)
        let financeTmp = this.state.payrollDataArray
        financeTmp.forEach(itm => {
            newArry.push({
                id : itm.id,
                employeeId : itm.employeeId,
                transactionDate : itm.dateCreated,
                isDeleted : itm.isDeleted,
                completionDate : itm.completionDate,
                isModified : itm.isModified,
                // payCardNumber: itm.payCardNumber,
                // paycardType: itm.paycardType,

            })
        })
        // let filterDate = ([...bdoArry, ...mbArry,...mlArry, ...palArry,...ubArry,...newArry])
        let newSetArray = ([...bdoArry, ...mbArry,...mlArry, ...palArry,...ubArry,...newArry,...lbArry,...gcashArry])
        
        // // console.log("bdoArry")
        // // console.log(bdoArry)

        let selectedData_ = newSetArray.filter( x => x.isDeleted === "1" || x.isModified ==="1")
        // console.log("selectedData_")
        // console.log(selectedData_) 
        
        selectedData_.forEach((itm) => {
            if(moment(itm.transactionDate).format("MM/DD/YYYY") > moment(this.state.dateCompletedTmp).format("MM/DD/YYYY")){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Invalid selection of Date Completed.",
                    fade            :   true,
                })
                return selectedData_ = []
            }
        })
        let validateSelected = newSetArray.filter( x => x.isDeleted === "1")
        // // // // // console.log("validateSelected")
        // // // // // console.log(validateSelected)
        let _fltrAttchment = this.state.payrollDataArray.filter(f => selectedData_.some(item => item.employeeId === f.employeeId && item.id === f.id))
        
        /* if(moment(this.state.dateCompletedTmp).format("MM/DD/YYYY") > moment(date).format("MM/DD/YYYY")){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Invalid selection of Date Completed. (future date)",
                fade            :   true,
            })
            return _fltrAttchment=[],  arrLst=[] 
            
        }
        else  */
        if(validateSelected.length===0){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select atleast 1 data to process",
                fade            :   true,
            })
            return newSetArray = [] 
            
        }
        else {
            _fltrAttchment.filter(x=>x.completionDate==="").forEach(itm => {
                let _userId = this.state.userinfo.userId
                arrLst.push({
                    "Id" : itm.id,
                    "ClientId":itm.clientId,
                    "ProfileId":itm.profileId,
                    "EmployeeId":itm.employeeId,
                    "PayPeriodId":itm.payPeriodId,
                    "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                    "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                    "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                    "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                    "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                    "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                    "IsSSSNumberMember":itm.isSSSNumberMember,
                    "IsTINNumberMember":itm.isTINNumberMember,
                    "IsPHICNumberMember":itm.isPHICNumberMember,
                    "IsHDMFNumberMember":itm.isHDMFNumberMember,
                    "IsCashCardMember":itm.isCashCardMember,
                    "IsValidIdMember":itm.isValidIdMember,
                    "IsResignationLetterMember":itm.isResignationLetterMember,
                    "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                    "IsCOERequestFormMember":itm.isCOERequestFormMember,
                    "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember,
                    "PaycardTypeIdMember":itm.paycardTypeIdMember,
                    "TypeOfCashCardMember":itm.typeOfCashCardMember,
                    "CashCardNumberMember":itm.cashCardNumberMember,
                    "IsCheckMember":itm.isCheckMember,
                    "IsMlhuillierMember":itm.isMlhuillierMember,
                    "IsPalawanMember":itm.isPalawanMember,
                    "RemarksMember":itm.remarksMember,
                    "NotesMember":itm.notesMember,
                    "ReasonForLeaving":itm.reasonForLeaving,
                    "DateForwardedToAmt": itm.dateForwardedToAmt,
                    "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                    "IsEligible":itm.isEligible,
                    "IsNotEligible":itm.isNotEligible,
                    "SssNumber": itm.sssNumber,
                    "TinNumber": itm.tinNumber,
                    "PhicNumber": itm.phicNumber,
                    "HdmfNumber": itm.hdmfNumber,
                    "IsClearedClient":itm.isClearedClient,
                    "IsEligibleClient":itm.isEligibleClient,
                    "IsNonEligibleClient":itm.isNonEligibleClient,
                    "RemarksClient":itm.remarksClient,
    
                    "IsClearedRmt":itm.isNonEligibleClient,
                    "IsReturnedRmt":itm.isNonEligibleClient,
                    "ValidateByRmt":itm.isNonEligibleClient,
                    "IsForwardToARERmt":itm.isNonEligibleClient ,
                    "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                    "IsCheckedByRmt":itm.isCheckedByRmt,
                    "RemarksRmt":itm.remarksRmt,
    
                    "RemarksLrd":itm.remarksLrd,
                    "ServicesRemarks":itm.servicesRemarks ,
    
                    "IsClearanceStatus": "10",
                    "clearanceDetails" :itm.clearanceDetails,
                        
                    /* ADDITIONAL */
                    "DateUpdatedByClient": itm.dateUpdatedByClient,
                    "UpdatedByClient":_userId,
    
                    "DateUpdatedByLRD":itm.dateUpdatedByLRD,
                    "UpdatedByLRD":_userId,
    
                    "DateUpdatedByRMT":itm.dateUpdatedByRMT,
                    "UpdatedByRMT":_userId,
    
                    "DateUpdatedByServices":itm.dateUpdatedByServices,
                    "UpdatedByServices":_userId,
    
                    "DateUpdatedByARE":itm.dateUpdatedByARE,
                    "UpdatedByARE":itm.isUpdatedByARE,
    
                    "DateUpdatedByFinance":itm.dateUpdatedByFinance,
                    "UpdatedByFinance":_userId,
                    "DateSubmittedByStaff":itm.dateSubmittedByStaff,
                    "SubmittedByStaff": _userId,
                    "DateSubmittedByLead":itm.dateSubmittedByLead,
                    "SubmittedByLead":_userId,
    
                    "DateReturnedByClient":itm.dateReturnedByClient,
                    "ReturnedByClient":_userId,
                    "DateReturnedByLRD":itm.dateReturnedByLRD,
                    "ReturnedByLRD":_userId,
                    "DateReturnedByRMT":itm.dateReturnedByRMT,
                    "ReturnedByRMT":_userId,
                    "DateReturnedByServices":itm.dateReturnedByServices,
                    "ReturnedByServices":_userId,
                    "DateReturnedByARE":itm.dateReturnedByARE,
                    "ReturnedByARE":_userId,
                    "DateReturnedByFinance":itm.dateReturnedByFinance,
                    "ReturnedByFinance":_userId,
                    
                    "DateSettle"        : itm.dateSettle,
                    "IsSettleWithDole"  :  itm.isSettleWithDole,
    
                    "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                    "IsCash" : itm.isCash,
                    "BatchNumber" : itm.batchNumber,
                    "servicesAttachments" : itm.servicesAttachments,
                    "clearanceDeductions":itm.clearanceDeductions,
    
                    
                    // 21/07/2023
                    "ParamountId" : itm.paramountId,
                    "LastSalary" : itm.lastSalary,
                    "Incentives" : itm.incentives,
                    "Sil" : itm.sil,
                    "ThirteenthMonth" : itm.thirteenthMonth,
                    "Inclusions" : itm.inclusions,
                    "GrossPay" : itm.grossPay,
                    "NetPay" : itm.netPay,
                    "totalBalance" : itm.totalBalance,
                    "PeriodCovered" : itm.periodCovered,
                    "IsModifiedByARE" : "0",
                    "SeparationPay" : itm.separationPay,
                    // "ReviewStatus" : "0",
                    "DateReturnedByPayroll" : "",
                    "AreRemarks" : itm.areRemarks,
                    "PayrollRemarks" : itm.payrollRemarks,
                    "FinanceRemarks" : itm.financeRemarks,
                    // "IsClearedSubmit" : "0",
                    "IsGenerated" : itm.isGenerated,
                    "IsFinalized" : itm.isFinalized,
                    "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                    "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                    'BatchPayroll' : itm.batchPayroll,
                    "Adjustment" : typeof itm.adjustment === "undefined" ? "" : itm.adjustment,
                    "OtherPayroll" : typeof itm.otherPayroll === "undefined" ? "" : itm.otherPayroll,
                    "Cbu" : typeof itm.cbu === "undefined" ? "" : itm.cbu,
                    "Dividend" : typeof itm.dividend === "undefined" ? "" : itm.dividend,
                    "TotalInclusion" : itm.totalInclusion,
                    "IsParent" : itm.isParent,
                    "IsChild" : itm.isChild,
                    "ParentId" : itm.parentId,
                    "SettlementFee" : itm.settlementFee,
                    "CompletionDate" : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY hh:mm:ss"),
                    "DateOfPayment" : moment(this.state.dateOfPaymentTmp).format("MM/DD/YYYY"),
                    "FinancePayoutDate" : moment(new Date()).format("MM/DD/YYYY"),
                    "DateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                    "ReviewStatus" : "2",
                    "IsClearedSubmit" : "1",
                    "IsAre" : "1",
                    "IsPayroll" : "1",
                    "LastModifiedBy" : this.state.userinfo.fullName
                    /* END */
                    
                })
                this.setState({ profileId : itm.employeeNo })
            })
            let param = {
                "IpAddress"             :"0.0.0.0",
                "UserId"                :this.state.userinfo.userId,
                "ClientId"              :this.state.userinfo.clientId,
                "clearance"             :arrLst
            }
            // console.log("Submit param")
            // console.log(param)
    
            await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
            .then(res => {
                let data = res.data; 
                
                if(res.data.status==="1"){
                    this.AddAres(_fltrAttchment)
                }
                else{
                    // console.log("res.data")
                    // console.log(res.data)
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "An error occured while processing your request, Please contact your System Administrator for : " + res.data.message,
                        fade        :   true
                    })
                }
            })
            .catch(error=>{
                // console.log("error line # 2021")
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
            // this.AddAres(_fltrAttchment)
        }
    }
    AddAres = async(dataList) =>{
        // let fltrDataArry = this.state.payrollDataArray.filter( x => x.isDeleted === "1" || x.isSelected === "1")
        // console.log("dataList Are")
        // console.log(dataList)

        let arrList = []
        dataList.forEach((itm,idx)=>{
            let _tmpEmployee = String(itm.employeeName).replace(',','')
            // console.log("itm.clearanceDeductions")
            // console.log(itm.clearanceDeductions)
            itm.clearanceDeductions/* .filter(x=>x.isClearance==="0") */.forEach((ded,indx)=>{
                let _credit = parseFloat(String(ded.balance).replace(',',''))
                // // // // // // console.log("ded")
                // // // // // // console.log(ded)
                arrList.push({
                    "amortization"      : "",
                    "batchNumber"       : "",
                    "client"            : itm.clientName,
                    "clientId"          : itm.clientId,
                    "referenceNo"       : "",
                    "collectionDate"    : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY"),
                    "createdby"         : String(this.state.userinfo.fullName).replace(',',''),
                    "credit"            : (_credit).toString(),
                    "cutOffDate"        : "",
                    "dateStart"         : "",
                    "debit"             : "0.00",
                    "deduction"         : ded.deduction,
                    "deductionId"       : ded.deductionId,
                    "deductionStatus"   : "",
    
                    "deductionType"     : ded.deductionType,
                    "deductionTypeId"   : ded.deductiontypeId,
                    "docNo"             : ded.docNo,
                    "employeeId"        : itm.employeeId,
                    "employeeName"      : _tmpEmployee,
                    "employeeNo"        : itm.employeeNo,
                    "groupName"         : "",
                    "groupNameId"       : "",
                    "hris"              : "HRIS",
                    "hrisId"            : "1",
                    "isDeleted"         : "0",
                    "isDraft"           : "0",
                    "isModified"        : "0",
                    "isPosted"          : "0",
                    "isPaid"            : "1",
                    "modifiedby"        : this.state.userinfo.userId,
                    "modifieddate"      : moment(new Date()).format('MM/DD/YYYY hh:mm'),
                    "numberOfDeduction" : "",
    
                    "payMode"           : "",
                    "payOutDate"        : "",
                    "payrollDate"       : "",
                    "remarks"           : "CLEARED",
                    "scheddeduction"    : "",
                    "seqNo"             : "",
                    "status"            : "",
                    "transactionDate"   : moment(new Date()).format("MM/DD/YYYY"),
                    "upload"            : "UPLOAD",
                    "isModified"        : "0", 
                    "isInclusion"       : "",
                    "inclusion"         : "",
                    "isMF"              : "",
                    "mf"                : "",
                    "priority"          : ded.priority,
                    "isClearance"       : "1",
                    "dateFinalized"     : moment(new Date()).format("MM/DD/YYYY"),
                    "dateSubmittedClearance"    : itm.createdDate,
                    "financePayoutDate"         : moment(new Date()).format("MM/DD/YYYY"),
                    "dateSubmittedToPayroll"    : itm.dateSubmittedToPayroll,
                })
            })
        })
        const params = {
            "dbname"      :  AppConfiguration.Setting().noseraredb,
            "_collection" : "SubsidiaryLedgerStaging",
            "doc_data"    : arrList
        }
        // console.log("params are")
        // console.log(params)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  params )
        .then(res =>{
            const data = res.data

            // console.log("data")
            // console.log(data)
        })
        .catch(error =>{
            // console.log("error line # 2122")
            // this.setState({
            //     isloading   :   false,
            //     alerttype   :   "Error!",
            //     isshow      :   true,
            //     color       :   "danger",
            //     message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            //     fade        :   true
            // })
        })
        this.AddAreInlcusion(dataList)
    }
    AddAreInlcusion = async(dataList) =>{
        // // console.log("dataList Inclusion Are")
        let filterDataList = []
        for(let i = 0; i < dataList.length; i++){
            if(dataList[i].parentId === ""){
                // console.log("parent")
                filterDataList = this.state.AreInlcusion.filter(f => dataList.some(item => item.employeeId === f.employeeId && f.isFloating==="0"))
            }
            else{
                // console.log("child")
                filterDataList = this.state.AreInlcusion.filter(f => dataList.some(item => item.employeeId === f.employeeId && f.isFloating==="1"))
            }
        }

        // // console.log('filterDataList')
        // // console.log(filterDataList)
        let arrList = []
        filterDataList.forEach((itm)=>{
            let _credit = parseFloat(String(itm.balance).replace(',',''))
            const obj = {
                "seqNo"             : itm.seqNo,
                "docNo"             : itm.docNo,
                "referenceNo"       : itm.referenceNo,
                "debit"             : "0.00",
                "credit"            : String(itm.balanceAmount),
                "collectionDate"    : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY"),
                "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),
                "payrollDate"       : moment(itm.payrollDate).format('MM/DD/YYYY'),

                "employeeId"        : itm.employeeId,
                "employeeName"      : itm.employeeName,
                "employeeNo"        : itm.employeeNo,

                "clientId"          : itm.clientId,
                "client"            : itm.client,
                "groupNameId"       : itm.groupNameId,
                "groupName"         : itm.groupName,

                "isInclusion"       : itm.isInclusion,
                "inclusion"         : itm.inclusion,
                "isMF"              : itm.isMF,
                "mf"                : itm.mf,
                "series"            : itm.series,
                "priority"          : itm.priority,

                "deductionId"       : itm.deductionId,
                "deduction"         : itm.deduction,
                "deductionTypeId"   : itm.deductionTypeId,
                "deductionType"     : itm.deductionType,
                "numberOfDeduction" : itm.numberOfDeduction,
                "remarks"           : itm.remarks,
                "batchNumber"       : itm.batchNumber,
                "amortization"      : itm.amortization,
                //"terms"             : itm.terms,
                //"amount"      : itm.balanceAmount,
                "hrisId"        : "1",
                "hris"          :"HRIS",
                "upload"   : itm.upload,
                "scheddeduction"    : itm.scheddeduction,
                //"payrollDate"       : "",
                "deductionStatus"   : itm.deductionStatus,
                "amortization"      : itm.amortization,

                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : "0",
                "isPaid"        : "1",
                "createdby"     : this.state.userinfo.fullName,
                "createddate"   : moment(new Date()).format('MM/DD/YYYY'),
                "modifiedby"    : this.state.userinfo.fullName,
                "modifieddate"  : moment(new Date()).format('MM/DD/YYYY'),
                "isModified"    : '0', 
                
                "payMode"       : itm.payMode,
                "payType"       : itm.payType,
                "cutOffDate"    : itm.cutOffDate,
                "employeeId"    : itm.employeeId,
                "profileId"     : itm.profileId,
                "status"        : itm.status,
                "clientId"      : itm.clientId,
                "isBegBalance" : itm.isBegBalance,
                "isLocked" : itm.isLocked,
                "lock" : itm.lock,
                "lockBy" : itm.lockBy,
                "lockedDate" : itm.lockedDate,
                "unLockedBy": itm.unLockedBy,
                "unLockedDate": itm.unLockedDate,
                "isSubmittedToPayroll": itm.isSubmittedToPayroll,
                "dateSubmittedToPayroll" : itm.dateSubmittedByPayroll,
                "dateSubmittedByPayroll" : itm.dateSubmittedByPayroll,
                "isClearance": "1",
                "financePayoutDate": moment(new Date()).format('MM/DD/YYYY'), // current date
                "remarks" : "CLOSED"
            }
            arrList.push(obj)

        })
        
        const addParams = {
            "dbname"      :  AppConfiguration.Setting().noseraredb,
            "_collection" : "SubsidiaryLedgerStaging",
            "doc_data"    : arrList
        }

        // console.log("params inclusion")
        // console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
        })
        .catch(error =>{
            // console.log("error line # 2245")
            this.setState({
                dateCompletedTmp : "",
                dateOfPaymentTmp : "",
                // isloading   :   false,
                // alerttype   :   "Error!",
                // isshow      :   true,
                // color       :   "danger",
                // message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                // fade        :   true
            })
        })
        this.UpdateUploadInc(dataList)
    }
    UpdateUploadInc = async(dataList) =>{
        // // console.log("arrList")
        // // console.log(arrList)
        let selectdData = this.state.payrollDataArray.filter(x=>x.isDeleted==="1")
        this.state.ledgerDG = this.state.AreInlcusion.filter(f => dataList.some(item => item.employeeId === f.employeeId ))
        
        // // console.log("filterDataList")
        // // console.log(filterDataList)

        for(let x = 0; x < this.state.ledgerDG.length; x++){
            let filter_data = { 
                "docNo" : this.state.ledgerDG[x]["docNo"], 
                "client" : this.state.ledgerDG[x]["client"], 
                "employeeNo" : this.state.ledgerDG[x]["employeeNo"], 
                "deductionId" : this.state.ledgerDG[x]["deductionId"], 
                "dateSubmittedToPayroll" : ""
            }
            let updateFields = {"isClearance" : "1", "isSubmittedToPayroll" : "1" }
            const updateParams = {
                "dbname"        : AppConfiguration.Setting().noseraredb,
                "_collection"   : "SubsidiaryLedgerStaging",
                "filterfields"  : filter_data,
                "updateFields"  : updateFields
            }
            // console.log("update header or charges");
            // console.log(updateParams);         
            await axios
            .post(AppConfiguration.Setting().noserareapi + "action/update_fields",  updateParams)
            .then(res => {
                const data = res.data  
                // console.log("data update header or charges");
                // console.log(data);  
            })
            .catch(error => {
                // console.log("error line # 2291")
                // this.setState({
                //     isloading   :   false,
                //     alerttype   :   "Error!",
                //     isshow      :   true,
                //     color       :   "danger",
                //     message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                //     fade        :   true
                // })
            })
        }
        this.UpdateInclusionLdger(dataList)
    };
    UpdateInclusionLdger = async(dataList) =>{
        // console.log("dataList")
        // console.log(dataList)
        // let selectdData = this.state.payrollDataArray.filter(x=>x.isDeleted==="1")
        this.state.ledgerDG = this.state.AreInlcusion.filter(f => dataList.some(item => item.employeeId === f.employeeId ))
        
        // // console.log("filterDataList")
        // // console.log(filterDataList)

        for(let x = 0; x < this.state.ledgerDG.length; x++){
            let filter_data = { 
                "docNo" : this.state.ledgerDG[x]["docNo"], 
                "deductionId" : this.state.ledgerDG[x]["deductionId"], 
            }
            let updateFields = {"isClearance" : "1"}
            const updateParams = {
                "dbname"        : AppConfiguration.Setting().noseraredb,
                "_collection"   : "InclusionLedger",
                "filterfields"  : filter_data,
                "updateFields"  : updateFields
            }
            // console.log("inclusion ledger update");
            // console.log(updateParams);         
            await axios
            .post(AppConfiguration.Setting().noserareapi + "action/update_fields",  updateParams)
            .then(res => {
                const data = res.data  
                // console.log("data inclusion ledger update");
                // console.log(data);  
            })
            .catch(error => {
                // console.log("error line # 2334")
                // this.setState({
                //     isloading   :   false,
                //     alerttype   :   "Error!",
                //     isshow      :   true,
                //     color       :   "danger",
                //     message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                //     fade        :   true
                // })
            })
        }
        this.UpdateHris(dataList)
    };
    UpdateHris = async(dataList) =>{
        
        // let fltrDataArry = this.state.payrollDataArray.filter( x => x.isDeleted === "1" || x.isSelected === "1")
        dataList.forEach((itm,idx)=>{
            let param = {
                "IpAddress":"0.0.0.0",
                "ClientId": this.state.userinfo.clientId, //--> session
                "UserId":this.state.userinfo.userId,
                "EmployeeId":itm.employeeId
            }
            // console.log("param Hris")
            // console.log(param)

            axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/UpdateClearanceStatus",  param)
            .then(res => {
                let data = res.data;
                this.setState({
                    dateCompletedTmp : "",
                    dateOfPaymentTmp : "",
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                });
                this.GetDataGrid();
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
        })
    }
    handleClickReturn = async(statusId) => {
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        
        let arrLst = []
        let newArry = []
        let bdoArry = []
        let mlArry = []
        let mbArry = []
        let palArry = []
        let ubArry = []

        let bdoTmp = this.state.bdoDataList/* .filter(x=>x.isDeleted==="1") */
        bdoTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                bdoArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    // isDeleted : itm.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                    isDeleted : x.isDeleted,
                })
            })
        })
        // // console.log("bdoArry")
        // // console.log(bdoArry)
        let metroTmp = this.state.metroBankDataList/* .filter(x=>x.isDeleted==="1") */
        metroTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                mbArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    isModified : itm.isModified,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber
                })
            })
        })
        // // // // // console.log("mbArry")
        // // // // // console.log(mbArry)
        let mlTmp = this.state.mlListData/* .filter(x=>x.isDeleted==="1") */
        mlTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                mlArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    isModified : itm.isModified,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber
                })
            })
        })
        // // // // // console.log("mlArry")
        // // // // // console.log(mlArry)
        let palawanTmp = this.state.palawanListData/* .filter(x=>x.isDeleted==="1") */
        palawanTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                palArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                })
            })
        })
        // // // // // console.log("palArry")
        // // // // // console.log(palArry)
        let ubTmp = this.state.ubListData/* .filter(x=>x.isDeleted==="1") */
        ubTmp.forEach((itm) => {
            itm.payrollFileDetails.forEach((x) => {
                ubArry.push({
                    id : x.payrollId,
                    employeeId : x.employeeId,
                    transactionDate : itm.txnDate,
                    isDeleted : x.isDeleted,
                    completionDate : x.completionDate,
                    paycardType: x.paycardCredited,
                    payCardNumber: x.payCardNumber,
                    isModified : itm.isModified,
                })
            })
        })
        // // // // // console.log("ubArry")
        // // // // // console.log(ubArry)
        let financeTmp = this.state.payrollDataArray
        financeTmp.forEach(itm => {
            newArry.push({
                id : itm.id,
                employeeId : itm.employeeId,
                transactionDate : itm.dateCreated,
                isDeleted : itm.isDeleted,
                completionDate : itm.completionDate,
                isModified : itm.isModified,
                // payCardNumber: itm.payCardNumber,
                // paycardType: itm.paycardType,

            })
        })
        // let filterDate = ([...bdoArry, ...mbArry,...mlArry, ...palArry,...ubArry,...newArry])
        let newSetArray = ([...bdoArry, ...mbArry,...mlArry, ...palArry,...ubArry,...newArry])
        
        let _slctdData = newSetArray.filter( x => x.isDeleted === "1")
        // console.log("_slctdData")
        // console.log(_slctdData)

        if (_slctdData.length === "0") {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select atleast 1 clearance to process.",
                fade            :   true
            })
            return
        }
        let _fltrAttchment = this.state.payrollDataArray.filter(f => _slctdData.some(item => item.employeeId === f.employeeId && item.id === f.id))

        _fltrAttchment.forEach(itm => {
            let _clearanceStatus = ""
            if (itm.isClearanceStatus === "2"){
                if (itm.reasonForLeaving === "Dole Cases"){
                    _clearanceStatus = "0"
                }else {
                    _clearanceStatus = String(parseFloat(itm.isClearanceStatus) - 1)
                }
            }else{
                _clearanceStatus = String(parseFloat(itm.isClearanceStatus) - 1)
            }
            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":itm.typeOfCashCardMember,
                "CashCardNumberMember":itm.cashCardNumberMember,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,

                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isCheckedByRmt,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": _clearanceStatus,
                "clearanceDetails" :itm.clearanceDetails,
                    
                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":this.state.userinfo.userId,

                "DateUpdatedByLRD":date,
                "UpdatedByLRD":this.state.userinfo.userId,

                "DateUpdatedByRMT":date,
                "UpdatedByRMT":this.state.userinfo.userId,

                "DateUpdatedByServices":date,
                "UpdatedByServices":this.state.userinfo.userId,

                "DateUpdatedByARE":itm.dateUpdatedByARE,
                "UpdatedByARE":itm.isUpdatedByARE,

                "DateUpdatedByFinance":date,
                "UpdatedByFinance":this.state.userinfo.userId,
                "DateSubmittedByStaff":date,
                "SubmittedByStaff": this.state.userinfo.userId,
                "DateSubmittedByLead":date,
                "SubmittedByLead":this.state.userinfo.userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":this.state.userinfo.userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":this.state.userinfo.userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":this.state.userinfo.userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":this.state.userinfo.userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":this.state.userinfo.userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":this.state.userinfo.userId,
                
                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":itm.clearanceDeductions,

                
                // 21/07/2023
                "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                "LastSalary" : itm.lastSalary,
                "Incentives" : itm.incentives,
                "Sil" : itm.sil,
                "ThirteenthMonth" : itm.thirteenthMonth,
                "Inclusions" : itm.inclusions,
                "GrossPay" : itm.grossPay,
                "NetPay" : itm.netPay,
                "IsModifiedByARE" : "0",
                "SeparationPay" : itm.separationPay,
                "ReviewStatus" : "0",
                "DateReturnedByPayroll" : date,
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks !== "" ? itm.payrollRemarks : this.state.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                "IsClearedSubmit" : "0",
                "IsGenerated" : itm.isGenerated,
                "totalBalance" : itm.totalBalance,
                "IsFinalized" : "0",
                // "ContractDateStart"  : typeof itm.contractDateStart === "undefined" ? "" : itm.contractDateStart,
                // "ContractDateEnd"  : typeof itm.contractDateEnd === "undefined" ? "" : itm.contractDateEnd,
                "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                'BatchPayroll' : "",
                "Adjustment" : typeof itm.Adjustment === "undefined" ? "" : itm.Adjustment,
                "OtherPayroll" : typeof itm.OtherPayroll === "undefined" ? "" : itm.OtherPayroll,
                "Cbu" : typeof itm.cbu === "undefined" ? "" : itm.cbu,
                "Dividend" : typeof itm.dividend === "undefined" ? "" : itm.dividend,
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                "SettlementFee" : itm.settlementFee,
                "CompletionDate" : itm.completionDate,
                "DateOfPayment" : itm.dateOfPayment,
                "FinancePayoutDate" : itm.FinancePayoutDate,
                "DateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                "ReviewStatus" : "2",
                "IsClearedSubmit" : "1",
                "IsAre" : "1",
                "IsPayroll" : "1",
                "LastModifiedBy" : this.state.userinfo.fullName
                /* END */
            
            })
            this.setState({ profileId : itm.profileId })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst
        }
        // // // // // console.log("Return param")
        // // // // // console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
            this.GetDataGrid();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }				
    
    
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false})
    }
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false})
    }
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false})
    }
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false})
    }
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false})
    }
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false})
    }
    handleChangedPayrollRemarks = (e) =>{
        this.setState({ financeRemarks : e.target.value.toUpperCase()})
    }
    handleChangeStatus = (e) =>{
        if (e.length === 0){
            this.state.statusId = ""
        } else {
            this.state.statusId = e[0].id
        }
    }
    handleChangeFromDateOfPaymentTmp = date =>{
        this.setState({ dateOfPaymentTmp: date, isshow:false , })
    }
    handleChangeToDateCompletedTmp = date =>{
        this.setState({ dateCompletedTmp: date, isshow:false , })
    }



    GridDataModifiedBdo(oldValue, newValue, id, column,) {
        let disable = true
        this.state.bdoDataList.forEach((item) => {
            item.payrollFileDetails.forEach((x) => {
                if (x.id===id){
                    x.isModified = newValue!=oldValue ? "1" : "0"
                }
            })
        })
        // this.setState({disablebtn:disable})
    };
    GridDataModifiedMetro(oldValue, newValue, id, column,) {
        let disable = true
        this.state.metroBankDataList.forEach((item) => {
            item.payrollFileDetails.forEach((x) => {
                if (x.id===id){
                    x.isModified = newValue!=oldValue ? "1" : "0"
                }
            })
        })
    };
    GridDataModifiedMl(oldValue, newValue, id, column,) {
        let disable = true
        this.state.mlListData.forEach((item) => {
            item.payrollFileDetails.forEach((x) => {
                if (x.id===id){
                    x.isModified = newValue!=oldValue ? "1" : "0"
                }
            })
        })
    };
    GridDataModifiedPalawan(oldValue, newValue, id, column,) {
        let disable = true
        this.state.palawanListData.forEach((item) => {
            item.payrollFileDetails.forEach((x) => {
                if (x.id===id){
                    x.isModified = newValue!=oldValue ? "1" : "0"
                }
            })
        })
    };
    GridDataModifiedUb(oldValue, newValue, id, column,) {
        let disable = true
        this.state.ubListData.forEach((item) => {
            item.payrollFileDetails.forEach((x) => {
                if (x.id===id){
                    x.isModified = newValue!=oldValue ? "1" : "0"
                }
            })
        })
    };  
    GridDataModifiedLb(oldValue, newValue, id, column,) {
        let disable = true
        this.state.lbListData.forEach((item) => {
            item.payrollFileDetails.forEach((x) => {
                if (x.id===id){
                    x.isModified = newValue!=oldValue ? "1" : "0"
                }
            })
        })
    };  
    GridDataModifiedGcash(oldValue, newValue, id, column,) {
        let disable = true
        this.state.gcashListData.forEach((item) => {
            item.payrollFileDetails.forEach((x) => {
                if (x.id===id){
                    x.isModified = newValue!=oldValue ? "1" : "0"
                }
            })
        })
    };  

    render() {
        var nf = new Intl.NumberFormat();
        let { navigate } = this.state;
        if(navigate){
            return <Redirect to="/clearance" push={true}/>
        };
        
        let { SearchBar, ClearSearchButton } = Search;
        
        const columnBDO = [
            {
                text        :   'REFERENCE NUMBER',
                editable    :   true,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            // {
            //     align: "center",
            //     checkbox: true,
            //     formatter:function (value, row, index) {
            //         if (row.completionDate !== ""){
            //             return {
            //                 disabled:true
            //             }
            //         }
            //         return value;
            //     }
            // }
        ]
        const columnMetroBank = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'20%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            /* {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            }, */
        ]
        const columnML = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'20%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            /* {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            }, */
        ]
        const columnPalawan = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'20%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            /* {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            }, */
        ]
        const columnUB = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'20%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            /* {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            }, */
        ]

        const columnLB = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'8%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            // {
            //     text        :   "ACTION",
            //     editable    :   false,
            //     dataField   :   "databasePkey",
            //     // formatter   :   (cell, row, isSelect) => {
            //     //     this.state.fileNameLinkMetro = row.fileName
            //     //     if (row)
            //     //     return (
            //     //         <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
            //     //             variant="link" onClick={e => this.downloadHandleClick(row)}
            //     //         >download file</Button>
            //     //     );
            //     // },
            //     // headerStyle : () => {
            //     //     return { width  : "10%",textAlign: 'center' };
            //     // },
            //     style:{textAlign:'center'}
            // },
        ]

        const columnGCASH = [
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            },
        ]

        
        const selectRowBDO = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableBDO=true
                this.state.bdoDataList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.payrollFileDetails.map(function(x,i){
                            if(x.payrollId===row.payrollId)
                            {
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                        })
                    }
                    if (item.isDeleted=="1")
                    isDisableBDO =  false
                })
                this.setState({isDisableBDO:isDisableBDO})
            }
        };
        const selectRowMetrobank = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableMetro=true
                this.state.metroBankDataList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisableMetro =  false
                })
                this.setState({isDisableMetro:isDisableMetro})
            }
        };
        const selectRowML = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableML=true
                this.state.mlListData.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisableML =  false
                })
                this.setState({isDisableML:isDisableML})
            }
        };
        const selectRowPalawan = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisablePalawan=true
                this.state.palawanListData.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisablePalawan =  false
                })
                this.setState({isDisablePalawan:isDisablePalawan})
            }
        };
        const selectRowUB = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableUB=true
                this.state.ubListData.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisableUB =  false
                })
                this.setState({isDisableUB:isDisableUB})
            }
        };
        const selectRowLB = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableLB=true
                this.state.lbListData.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisableLB =  false
                })
                this.setState({isDisableLB:isDisableLB})
            }
        };
        const selectRowGCASH = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableGCash=true
                this.state.gcashListData.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisableGCash =  false
                })
                this.setState({isDisableGCash:isDisableGCash})
            }
        };


        
        const columnBDOExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "CLIENT name",
                editable    :   false,
                dataField   :   "clientName",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%' }},
                
            },
            {
                text        :   "Member Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%' }},
                
            },
            {
                text        :   "Pay Card Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            
            {
                text        :   "Date Completed",
                editable    :   false,
                dataField   :   "completionDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            /* {
                text        :   "Paycard",
                editable    :   false,
                dataField   :   "paycardType",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardType!='' && row.paycardType!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            }, */
            {
                text        :   "Actual paycard Credited",
                editable    :   true,
                dataField   :   "paycardCredited",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardCredited!='' && row.paycardCredited!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            },
        ]
        const columnMetroBankExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "CLIENT name",
                editable    :   false,
                dataField   :   "clientName",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%' }},
                
            },
            {
                text        :   "Member Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%' }},
                
            },
            {
                text        :   "Pay Card Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            
            {
                text        :   "Date Completed",
                editable    :   false,
                dataField   :   "completionDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            /* {
                text        :   "Paycard",
                editable    :   false,
                dataField   :   "paycardType",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardType!='' && row.paycardType!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            }, */
            {
                text        :   "Actual paycard Credited",
                editable    :   true,
                dataField   :   "paycardCredited",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardCredited!='' && row.paycardCredited!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            },
        ]
        const columnMLExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "CLIENT name",
                editable    :   false,
                dataField   :   "clientName",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%' }},
                
            },
            {
                text        :   "Reference Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Sender Last Name",
                editable    :   false,
                dataField   :   "senderLastName",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Sender First Name",
                editable    :   false,
                dataField   :   "senderFirstName",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "Sender Middle Name",
                editable    :   false,
                dataField   :   "senderMiddleName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "Sender Address",
                editable    :   false,
                dataField   :   "senderAddress",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Sender Contact No",
                editable    :   false,
                dataField   :   "senderContactNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "Receiver Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Receiver Address",
                editable    :   false,
                dataField   :   "receiverAddress",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Receiver Contact No",
                editable    :   false,
                dataField   :   "receiverContactNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Date Completed",
                editable    :   false,
                dataField   :   "completionDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%' }},
                
            },
            /* {
                text        :   "Paycard",
                editable    :   false,
                dataField   :   "paycardType",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardType!='' && row.paycardType!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            }, */
            {
                text        :   "Actual paycard Credited",
                editable    :   true,
                dataField   :   "paycardCredited",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardCredited!='' && row.paycardCredited!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            },
        ]
        const columnPalawanExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "CLIENT NAME",
                editable    :   false,
                dataField   :   "clientName",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%' }},
                
            },
            {
                text        :   "Sender FN",
                editable    :   false,
                dataField   :   "senderLastName",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Sender LN",
                editable    :   false,
                dataField   :   "senderFirstName",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "Sender MPN",
                editable    :   false,
                dataField   :   "senderContactNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "To Branch",
                editable    :   false,
                dataField   :   "senderAddress",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "First Name",
                editable    :   false,
                dataField   :   "receiverFirstName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Middle Initial",
                editable    :   false,
                dataField   :   "receiverMiddleName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Last Name",
                editable    :   false,
                dataField   :   "receiverLastName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "MPN",
                editable    :   false,
                dataField   :   "receiverContactNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Date Completed",
                editable    :   false,
                dataField   :   "completionDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%' }},
                
            },
            /* {
                text        :   "Paycard",
                editable    :   false,
                dataField   :   "paycardType",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardType!='' && row.paycardType!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            }, */
            {
                text        :   "Actual paycard Credited",
                editable    :   true,
                dataField   :   "paycardCredited",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardCredited!='' && row.paycardCredited!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            },
        ]
        const columnUBExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Member Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%' }},
                
            },
            {
                text        :   "CLIENT NAME",
                editable    :   false,
                dataField   :   "clientName",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%' }},
                
            },
            {
                text        :   "Pay Card Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Date Completed",
                editable    :   false,
                dataField   :   "completionDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            /* {
                text        :   "Paycard",
                editable    :   false,
                dataField   :   "paycardType",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardType!='' && row.paycardType!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            }, */
            {
                text        :   "Actual paycard Credited",
                editable    :   true,
                dataField   :   "paycardCredited",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardCredited!='' && row.paycardCredited!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            },
        ]
        const columnLBExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Member Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%' }},
                
            },
            {
                text        :   "CLIENT NAME",
                editable    :   false,
                dataField   :   "clientName",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%' }},
                
            },
            {
                text        :   "Pay Card Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Date Completed",
                editable    :   false,
                dataField   :   "completionDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            /* {
                text        :   "Paycard",
                editable    :   false,
                dataField   :   "paycardType",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardType!='' && row.paycardType!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            }, */
            {
                text        :   "Actual paycard Credited",
                editable    :   true,
                dataField   :   "paycardCredited",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardCredited!='' && row.paycardCredited!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            },
        ]
        const columnGCashExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Member Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%' }},
                
            },
            {
                text        :   "CLIENT NAME",
                editable    :   false,
                dataField   :   "clientName",
                headerStyle: (colum, colIndex) => {
                    return {width:'30%' }},
                
            },
            {
                text        :   "Pay Card Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            {
                text        :   "Date Completed",
                editable    :   false,
                dataField   :   "completionDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                
            },
            /* {
                text        :   "Paycard",
                editable    :   false,
                dataField   :   "paycardType",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardType!='' && row.paycardType!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            }, */
            {
                text        :   "Actual paycard Credited",
                editable    :   true,
                dataField   :   "paycardCredited",
                headerStyle: (colum, colIndex) => {
                    return {width:'10%' }},
                formatter: (cell, row) => {
                    if(row.paycardCredited!='' && row.paycardCredited!=null){
                        if(this.state.paycardtypes.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.paycardtypes.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardtypes
                },
                
            },
        ]
        const expandBDORowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "payrollId"
                    data = { row.payrollFileDetails }
                    columns = { columnBDOExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                    selectRow={selectRowExpand}
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, rows) => {
                            this.GridDataModifiedBdo(oldValue, newValue, row, row.employeeNo)
                            }
                        })
                    }
                /> 
            ),
            showExpandColumn: true,
            
        };
        const selectRowExpand = {
            mode: 'checkbox',
            // hideSelectAll: true,
            clickToExpand: true,
            clickToSelectAndEditCell : true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.bdoDataList.map(function(item,i){
                    item.payrollFileDetails.map(function(x,i){
                            if(x.payrollId===row.payrollId){
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                    })
                })
            },
            onSelectAll: (isSelect, row) => {
                this.state.bdoDataList.map(function(itm){
                    itm.payrollFileDetails.map(function(x,i){
                        row.map(function(z,i){
                            if(x.payrollId===z.payrollId)
                            {
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                        })
                    })
                })
            }

        };
        const expandMetroBankRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "payrollId"
                    data = { row.payrollFileDetails }
                    columns = { columnMetroBankExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                //   rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                    selectRow={selectRowExpandMb}
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, rows) => {
                            this.GridDataModifiedMetro(oldValue, newValue, row, row.employeeNo)
                            }
                        })
                    }
                /> 
            ),
            showExpandColumn: true,
            
        };
        const selectRowExpandMb = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToSelectAndEditCell : true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.metroBankDataList.map(function(item,i){
                    item.payrollFileDetails.map(function(x,i){
                            if(x.payrollId===row.payrollId){
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                    })
                })
            },
            onSelectAll: (isSelect, row) => {
                this.state.metroBankDataList.map(function(itm){
                    itm.payrollFileDetails.map(function(x,i){
                        row.map(function(z,i){
                            if(x.payrollId===z.payrollId)
                            {
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                        })
                    })
                })
            }
        };
        const expandMLRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "payrollId"
                    data = { row.payrollFileDetails }
                    columns = { columnMLExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                    selectRow={selectRowExpandMl}
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, rows) => {
                            this.GridDataModifiedMl(oldValue, newValue, row, row.employeeNo)
                            }
                        })
                    }
                /> 
            ),
            showExpandColumn: true,
            
        };
        const selectRowExpandMl = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToSelectAndEditCell : true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.mlListData.map(function(item,i){
                    item.payrollFileDetails.map(function(x,i){
                            if(x.payrollId===row.payrollId){
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                    })
                })
            },
            onSelectAll: (isSelect, row) => {
                this.state.mlListData.map(function(itm){
                    itm.payrollFileDetails.map(function(x,i){
                        row.map(function(z,i){
                            if(x.payrollId===z.payrollId)
                            {
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                        })
                    })
                })
            }
        };
        const expandPalawanRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "payrollId"
                    data = { row.payrollFileDetails }
                    columns = { columnPalawanExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                    selectRow={selectRowExpandPal}
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, rows) => {
                            this.GridDataModifiedPalawan(oldValue, newValue, row, row.employeeNo)
                            }
                        })
                    }
                /> 
            ),
            showExpandColumn: true,
            
        };
        const selectRowExpandPal = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToSelectAndEditCell : true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.palawanListData.map(function(item,i){
                    item.payrollFileDetails.map(function(x,i){
                            if(x.payrollId===row.payrollId){
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                    })
                })
            },
            onSelectAll: (isSelect, row) => {
                this.state.palawanListData.map(function(itm){
                    itm.payrollFileDetails.map(function(x,i){
                        row.map(function(z,i){
                            if(x.payrollId===z.payrollId)
                            {
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                        })
                    })
                })
            }
        };
        const expandUBRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "payrollId"
                    data = { row.payrollFileDetails }
                    columns = { columnUBExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                    selectRow={selectRowExpandUB}
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, rows) => {
                            this.GridDataModifiedUb(oldValue, newValue, row, row.employeeNo)
                            }
                        })
                    }
                /> 
            ),
            showExpandColumn: true,
            
        };
        const selectRowExpandUB = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToSelectAndEditCell : true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.ubListData.map(function(item,i){
                    item.payrollFileDetails.map(function(x,i){
                            if(x.payrollId===row.payrollId){
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                    })
                })
            },
            onSelectAll: (isSelect, row) => {
                this.state.ubListData.map(function(itm){
                    itm.payrollFileDetails.map(function(x,i){
                        row.map(function(z,i){
                            if(x.payrollId===z.payrollId)
                            {
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                        })
                    })
                })
            }
        };
        const expandLBRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "payrollId"
                    data = { row.payrollFileDetails }
                    columns = { columnLBExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                    selectRow={selectRowExpandLb}
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, rows) => {
                            this.GridDataModifiedLb(oldValue, newValue, row, row.employeeNo)
                            }
                        })
                    }
                /> 
            ),
            showExpandColumn: true,
            
        };
        const selectRowExpandLb = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToSelectAndEditCell : true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.lbListData.map(function(item,i){
                    item.payrollFileDetails.map(function(x,i){
                            if(x.payrollId===row.payrollId){
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                    })
                })
            },
            onSelectAll: (isSelect, row) => {
                this.state.lbListData.map(function(itm){
                    itm.payrollFileDetails.map(function(x,i){
                        row.map(function(z,i){
                            if(x.payrollId===z.payrollId)
                            {
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                        })
                    })
                })
            }
        };
        const expandGCashRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "payrollId"
                    data = { row.payrollFileDetails }
                    columns = { columnGCashExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                    selectRow={selectRowExpandGcash}
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, rows) => {
                            this.GridDataModifiedGcash(oldValue, newValue, row, row.employeeNo)
                            }
                        })
                    }
                /> 
            ),
            showExpandColumn: true,
            
        };
        const selectRowExpandGcash = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToSelectAndEditCell : true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.gcashListData.map(function(item,i){
                    item.payrollFileDetails.map(function(x,i){
                        // // console.log("x.payrollId")
                        // // console.log(x.payrollId)
                        // // console.log("row.payrollId")
                        // // console.log(row.payrollId)
                            if(x.payrollId===row.payrollId){
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                    })
                })
            },
            onSelectAll: (isSelect, row) => {
                this.state.gcashListData.map(function(itm){
                    itm.payrollFileDetails.map(function(x,i){
                        row.map(function(z,i){
                            if(x.payrollId===z.payrollId)
                            {
                                x.isDeleted = isSelect ? "1" : "0"
                            }
                        })
                    })
                })
            }
        };

        
        let financeColumn = [
            {
                dataField: 'batchPayroll',
                text: 'REFERENCE NUMBER',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%", textAlign: "center" };
                },
                style:{textAlign : "center"}
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE',
                editable: false,
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO.',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField: 'netPay',
                text: 'NET PAY',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%"};
                },
                style:{textAlign : "right"}
            },
            // {
            //     dataField: 'dateOfPayment',
            //     text: 'DATE OF PAYMENT',
            //     editable: true,
            //     headerStyle: () => {
            //         return { width: "10%" };
            //     },
            //     formatter: (cell, row) => {
            //         if(row.drafterName!='' && row.drafterName!=null){
            //             return this.state.drafterNameDDL.find(x => x.value == cell).label;
            //         }
            //     },
            //     editor: {
            //         type: Type.SELECT,
            //         options: this.state.drafterNameDDL
            //     }
            // },
            {
                dataField: 'completionDate',
                text: 'DATE COMPLETED',
                editable: false,
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: '',
                text: 'MODE OF PAYMENT',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if(row.isTypeOfCashCardMember === "1" && row.isCheckMember ==="0" && row.isCash  === "0"){
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                                {row.typeOfCashCardMember}
                            </Form.Label>   
                        )
                    } 
                    else if (row.isTypeOfCashCardMember === "0" && row.isCheckMember ==="1" && row.isCash  === "0") {
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                            CHECK
                            </Form.Label>   
                        )
                    } 
                    else if (row.isTypeOfCashCardMember === "0" && row.isCheckMember ==="0" && row.isCash  === "1") {
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                            CASH
                            </Form.Label>  

                        )
                    }
                    // else if (row.isTypeOfCashCardMember === "0" && row.isCheckMember ==="0" && row.isCash  === "0") {
                    //     return (
                    //         <Form.Label column sm="12" style={{textAlign:"left"}}>
                    //             YES
                    //         </Form.Label>  

                    //     )
                    // }  
                }
            },
            {
                dataField: 'parentId',
                text: 'CLEARANCE TYPE',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%" };
                },
                style:{textAlign:"left"},
                formatter   :   (cell, row, isSelect) => {
                    if(row.parentId === ""){
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                                Parent
                            </Form.Label>   
                        )
                    } 
                    else{
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                                Child
                            </Form.Label>   
                        )
                    }  
                },
            },
            {
                dataField: 'proofOfPayment',
                text: 'PROOF OF PAYMENT',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%" };
                }
            },
        ]
        let selectRowFinance = {
            mode: 'checkbox',
            clickToSelectAndEditCell: false,
            // selected : this.state.selectedId,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.payrollDataArray.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        // // // // console.log(item)
                    }
                })
            },
            onSelectAll: (isSelect, row) => {
                let isDisable = true
                this.state.payrollDataArray.map(function(itm){
                    itm.isDeleted = isSelect ? "1" : "0"
                })
            }
        };
    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; FINANCE MODULE</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <div>
                                    {/* <Accordion className="mt-2">
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                    CLICK TO SEARCH
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0"> */}
                                                <Card.Body>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        Employee
                                                        </Form.Label>
                                                        <Col sm="10">
                                                            <Typeahead
                                                                ref={this.client}
                                                                labelKey='employeeName'
                                                                id="basic-example"
                                                                onChange={this.handleChangeEmployeeName}
                                                                options={this.state.searchNames}
                                                                placeholder='Select Employee'
                                                                //selected={[this.state.clientName]}
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        
                                                        </Form.Label>
                                                        <Col>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        Reference Number
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.client}
                                                                labelKey='batchPayroll'
                                                                id="basic-example"
                                                                onChange={this.handleChangeClient}
                                                                options={this.state.cleranceList}
                                                                placeholder='Select Ref. No.'
                                                                //selected={[this.state.clientName]}
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        Batch Number
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.employee}
                                                                labelKey='batchNumber'
                                                                id="basic-example"
                                                                onChange={this.handleChangeEmployee}
                                                                options={this.state.searchList}
                                                                placeholder='Select Batch. No.'
                                                                //defaultSelected={[this.state.employeeName]}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Date Completed
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='fromDateCreated'
                                                                selected={this.state.fromDateCreated}
                                                                onChange={this.handleChangeFromDateCreated}
                                                                value={this.props.fromDateCreated}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Date Completed
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='toDateCreated'
                                                                selected={this.state.toDateCreated}
                                                                onChange={this.handleChangeToDateCreated}
                                                                value={this.props.toDateCreated}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </Card.Body>
                                            {/* </Accordion.Collapse>
                                        </Card>
                                    </Accordion> */}
                                </div>
                                <ButtonToolbar className="mt-3">
                                    <Button className="ml-auto noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickRefresh }>
                                        Refresh
                                    </Button> 
                                    <Button variant="primary" className="noser-button-mr1 noser-button" onClick={ this.handleClickSearch }>
                                        Search
                                    </Button>
                                    {/* <Button variant="info" className="noser-button" >
                                        <CSVLink style={{ color : "white" }} onClick ={this.GridExcellReports} filename= "Batch_Clearance.xls" data={this.state.gridDataExcel}>Export</CSVLink>
                                    </Button> */}
                                </ButtonToolbar>
                                <div className="mt-2">
                                    <Tabs className="mt-3" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                        <Tab eventKey="default" title="BDO">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <div className="card-header-tab"></div>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.bdoDataList }
                                                            columns = { columnBDO }
                                                            // selectRow = { selectRowBDO }
                                                            rowClasses="noser-table-row-class no-checkbox"
                                                            striped
                                                            hover
                                                            condensed
                                                            expandRow={ expandBDORowDetails }
                                                            noDataIndication={ () => <div>No record found.</div> }
                                                        />
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>

                                        <Tab eventKey="metrobank" title="METROBANK">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <div className="card-header-tab"></div>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.metroBankDataList }
                                                            columns = { columnMetroBank }
                                                            // selectRow = { selectRowMetrobank }
                                                            striped
                                                            hover
                                                            condensed
                                                            expandRow={ expandMetroBankRowDetails }
                                                            noDataIndication={ () => <div>No record found.</div> }
                                                            rowClasses="noser-table-row-class no-checkbox"
                                                        />
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>

                                        <Tab eventKey="ml" title="ML">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <div className="card-header-tab"></div>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.mlListData }
                                                            /* data = { this.state.mlListData } */
                                                            columns = { columnML }
                                                            // selectRow = { selectRowML }
                                                            striped
                                                            hover
                                                            condensed
                                                            expandRow={ expandMLRowDetails }
                                                            noDataIndication={ () => <div>No record found.</div> }
                                                            rowClasses="noser-table-row-class no-checkbox"
                                                        />
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>

                                        <Tab eventKey="palawan" title="PALAWAN">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <div className="card-header-tab"></div>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.palawanListData }
                                                            /* data = { this.state.mlListData } */
                                                            columns = { columnPalawan }
                                                            // selectRow = { selectRowPalawan }
                                                            striped
                                                            hover
                                                            condensed
                                                            expandRow={ expandPalawanRowDetails }
                                                            noDataIndication={ () => <div>No record found.</div> }
                                                            rowClasses="noser-table-row-class no-checkbox"
                                                        />
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>
                                        <Tab eventKey="ub" title="UB">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <div className="card-header-tab"></div>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.ubListData }
                                                            columns = { columnUB }
                                                            // selectRow = { selectRowUB }
                                                            striped
                                                            hover
                                                            condensed
                                                            expandRow={ expandUBRowDetails }
                                                            noDataIndication={ () => <div>No record found.</div> }
                                                            rowClasses="noser-table-row-class no-checkbox"
                                                        />
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>

                                        <Tab eventKey="lb" title="LB">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <div className="card-header-tab"></div>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.lbListData }
                                                            columns = { columnLB }
                                                            // selectRow = { selectRowLB }
                                                            striped
                                                            hover
                                                            condensed
                                                            expandRow={ expandLBRowDetails }
                                                            noDataIndication={ () => <div>No record found.</div> }
                                                        />
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>

                                        <Tab eventKey="gcash" title="GCASH">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <div className="card-header-tab"></div>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.gcashListData }
                                                            columns = { columnGCASH }
                                                            // selectRow = { selectRowGCASH }
                                                            striped
                                                            hover
                                                            condensed
                                                            expandRow={ expandGCashRowDetails }
                                                            noDataIndication={ () => <div>No record found.</div> }
                                                        />
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>
                                        <Tab eventKey="cashCheck" title="Cash / Check">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <div className="card-header-tab"></div>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.payrollDataArray.filter(x=>parseFloat(x.isClearanceStatus)>=8 && x.isTypeOfCashCardMember ==="0" ) }
                                                            columns = { financeColumn }
                                                            selectRow = { selectRowFinance }
                                                            striped
                                                            hover
                                                            condensed
                                                            // expandRow={ expandUBRowDetails }
                                                            noDataIndication={ () => <div>No record found.</div> }
                                                        />
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>
                                    </Tabs>
                                
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DATE OF PAYMENT
                                        </Form.Label>
                                        <Col>
                                            <DatePicker
                                                ref='dateOfPaymentTmp'
                                                selected={this.state.dateOfPaymentTmp}
                                                onChange={this.handleChangeFromDateOfPaymentTmp}
                                                value={this.props.dateOfPaymentTmp}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                // placeholder='SELECT CLEARANCE DATE'
                                                // wrapperClassName="datepicker"
                                            />
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DATE COMPLETED
                                        </Form.Label>
                                        <Col>
                                            <DatePicker
                                                ref='dateCompletedTmp'
                                                selected={this.state.dateCompletedTmp}
                                                onChange={this.handleChangeToDateCompletedTmp}
                                                value={this.props.dateCompletedTmp}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                maxDate={new Date()}
                                                // placeholder='SELECT CLEARANCE DATE'
                                                // wrapperClassName="datepicker"
                                            />
                                        </Col>
                                    </Form.Group>
                                </div>
                                <ButtonToolbar sm={12}>
                                    <Button onClick={ this.handleClickClose } /* disabled = {true} */ className="ml-auto noser-button-mr1 noser-button" variant="info" >
                                        CLOSE
                                    </Button>
                                    <Button onClick={ this.handleClickReject } /* disabled = {true} */ className="noser-button-mr1 noser-button" variant="info" >
                                        REJECT
                                    </Button>
                                    <Button onClick={this.handleClickReturn } className="noser-button"  variant="info">
                                        return
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }
}

export  default ClearanceFinance
